import { useQuery } from '@apollo/client/react/hooks'
import { useEffect, useState } from 'react'
import { MeetingRecording } from 'src/__generated__/graphql'
import { getMeetingRecordings, recordingStatusUpdatedSubscription } from 'src/graphql/queries'
import { findActiveRecording } from 'src/utils/meetings'

// ----------------------------------------------------------------------

type Props = {
  subscribeToMore?: boolean
  includeScheduled?: boolean
}

/**
 * A hook to get any in-flight meeting recordings
 * @param param
 * @returns
 */
export function useInFlightRecording({ subscribeToMore = true, includeScheduled = false }: Props) {
  const [inFlightRecording, setCurrentRecording] = useState<MeetingRecording>()
  const {
    data: meetingRecordings,
    loading,
    subscribeToMore: subscribeToMoreMeetingRecordings,
    refetch,
  } = useQuery(getMeetingRecordings, {
    variables: {
      includeCompleted: false,
      includeScheduled,
    },
  })

  // set current recording
  useEffect(() => {
    const recording = findActiveRecording(meetingRecordings?.meetingRecordings)
    setCurrentRecording(recording)
  }, [meetingRecordings])

  // subscribe to recording status updates
  useEffect(() => {
    if (subscribeToMore) {
      subscribeToMoreMeetingRecordings({
        document: recordingStatusUpdatedSubscription,
        updateQuery: (prev) => {
          refetch()
          return prev
        },
      })
    }
  }, [refetch, subscribeToMore, subscribeToMoreMeetingRecordings])

  return {
    inFlightRecording,
    loading,
  }
}
