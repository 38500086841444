import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import Fade from '@mui/material/Fade'
import { useBoolean } from 'src/hooks/use-boolean'
import ContactUsCalendlyDialog from './contact-us-calendly'

export default function FreeTrialEndedCard() {
  const calendlyDialog = useBoolean()

  return (
    <Fade in>
      <Stack
        sx={{
          px: 2,
          pt: 2,
          textAlign: 'center',
        }}
      >
        <Box sx={{ background: 'linear-gradient(to bottom, #fe8966, #c3d457)', padding: '2px', borderRadius: '16px' }}>
          <Paper sx={(theme) => ({ borderRadius: 2, p: 2, backgroundColor: theme.palette.background.paper })}>
            <Stack spacing={3} alignItems="center">
              <Stack spacing={0.25}>
                <Typography variant="subtitle1">Your free trial has ended</Typography>
                <Typography variant="body2" color="text.secondary">
                  Contact us to start a plan and continue recording meetings
                </Typography>
              </Stack>
              <Button variant="contained" color="warning" sx={{ width: 'fit-content' }} onClick={calendlyDialog.onTrue}>
                <Typography variant="button" color="warning.darker" fontWeight={800}>
                  Contact Us
                </Typography>
              </Button>
            </Stack>
          </Paper>
        </Box>
        <ContactUsCalendlyDialog open={calendlyDialog.value} onClose={calendlyDialog.onFalse} />
      </Stack>
    </Fade>
  )
}
