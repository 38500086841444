import { memo, useState, useCallback } from 'react'
// @mui
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import Collapse from '@mui/material/Collapse'
//
import Iconify from 'src/components/iconify'
import { NavSectionProps, NavListProps, NavConfigProps } from '../types'
import { navVerticalConfig } from '../config'
import { StyledSubheader } from './styles'
import NavList from './nav-list'

// ----------------------------------------------------------------------

function NavSectionVertical({ data, config, sx, ...other }: NavSectionProps) {
  return (
    <Stack sx={sx} {...other}>
      {data.map((group, index) => (
        <Group
          key={group.subheader || index}
          subheader={group.subheader}
          items={group.items}
          config={navVerticalConfig(config)}
        />
      ))}
    </Stack>
  )
}

export default memo(NavSectionVertical)

// ----------------------------------------------------------------------

type GroupProps = {
  subheader: string
  items: NavListProps[]
  config: NavConfigProps
}

function Group({ subheader, items, config }: GroupProps) {
  const [open, setOpen] = useState(true)

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  const renderContent = items
    .filter((item) => !item.onlyShowForNavSize || item.onlyShowForNavSize === 'vertical')
    .map((list) => (
      <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} config={config} />
    ))

  return (
    <List disablePadding sx={{ px: 1 }}>
      {subheader ? (
        <>
          <StyledSubheader disableGutters disableSticky config={config} sx={{ width: 1 }}>
            {subheader}
            {subheader.trim() && (
              <Iconify
                width={24}
                onClick={handleToggle}
                icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
                sx={{
                  ml: 'auto',
                  flexShrink: 0,
                  p: 0.5,
                  '&:hover': {
                    backgroundColor: 'action.hover',
                    borderRadius: 6,
                  },
                }}
              />
            )}
          </StyledSubheader>

          <Collapse in={open}>{renderContent}</Collapse>
        </>
      ) : (
        renderContent
      )}
    </List>
  )
}
