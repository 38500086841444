import { createContext, useContext } from 'react'
import { IntercomCustomUserAttributes } from './types'
//

// ----------------------------------------------------------------------

export type IntercomState = {
  unreadMessages: number
  trackIntercomEvent: (eventName: string, metaData?: object) => void
  updateIntercomCutomUserProps: (props: Partial<IntercomCustomUserAttributes>) => void
}

export const AmbientIntercomProviderContext = createContext({} as IntercomState)

export const useAmbientIntercomContext = () => {
  const context = useContext(AmbientIntercomProviderContext)

  if (!context) throw new Error('useAmbientIntercomContext must be use inside AmbientIntercomProvider')

  return context
}
