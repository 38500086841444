import { useCallback, useEffect, useMemo, useRef } from 'react'

const channelInstances: { [key: string]: BroadcastChannel } = {}

const getSingletonChannel = (name: string): BroadcastChannel => {
  if (!channelInstances[name]) {
    channelInstances[name] = new BroadcastChannel(name)
  }
  return channelInstances[name]
}

export default function useBroadcastChannel<T>(channelName: string, onMessageReceived: (message: T) => void) {
  const channel = useMemo(() => getSingletonChannel(channelName), [channelName])
  const isSubscribed = useRef(false)

  useEffect(() => {
    if (!isSubscribed.current || process.env.NODE_ENV !== 'development') {
      channel.onmessage = (event) => onMessageReceived(event.data as T)
    }
    return () => {
      if (isSubscribed.current || process.env.NODE_ENV !== 'development') {
        channel.close()
        isSubscribed.current = true
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const postMessage = useCallback(
    (message: T) => {
      channel?.postMessage(message)
    },
    [channel]
  )

  return {
    postMessage,
  }
}
