// @mui
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
// theme
import { hideScroll } from 'src/theme/css'
// components
import Logo from 'src/components/logo'
import { NavSectionMini } from 'src/components/nav-section'
//
import { useDesktopApp } from 'src/hooks/use-desktop-app'
import { useTheme } from '@mui/material'
import { NAV } from '../config-layout'
import { useNavData } from './config-navigation'
import { MeetingBotNavControls, NavToggleButton } from '../_common'

// ----------------------------------------------------------------------

export default function NavMini() {
  const { palette } = useTheme()
  const navData = useNavData()
  const { desktopHeaderHeight, isDesktopApp } = useDesktopApp()

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { [NAV.SHOW_VERT_NAVBAR_SIZE]: 0 },
        width: { [NAV.SHOW_VERT_NAVBAR_SIZE]: NAV.W_MINI },
        backgroundColor: palette.nav.background,
      }}
    >
      <NavToggleButton
        sx={{
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          // Account for the traffic lights on desktop app
          pt: `${desktopHeaderHeight / 2}px`,
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: `dashed 1px ${palette.nav.divider}`,
          ...hideScroll.x,
        }}
      >
        {/* Desktop apps don't generally have a logo in the nav bar */}
        {!isDesktopApp && <Logo sx={{ mx: 'auto', my: 3, width: 24 }} />}
        {isDesktopApp && <Box sx={{ height: 60 }} />}
        <NavSectionMini
          data={navData}
          config={{
            iconSize: 20,
            itemGap: 2,
          }}
        />
        <Box sx={(theme) => ({ height: theme.spacing(5) })} />
        <MeetingBotNavControls mini />
      </Stack>
    </Box>
  )
}
