import { selectClasses } from '@mui/material'
import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function select(theme: Theme) {
  return {
    MuiSelect: {
      styleOverrides: {
        [selectClasses.root]: {
          borderRadius: theme.shape.borderRadius * 0.8,
        },
        icon: {
          right: 10,
          width: 18,
          height: 18,
          top: 'calc(50% - 9px)',
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        icon: {
          right: 10,
          width: 18,
          height: 18,
          top: 'calc(50% - 9px)',
        },
      },
    },
  }
}
