import isEqual from 'lodash/isEqual'
import { useEffect, useMemo, useCallback, useState } from 'react'
// hooks
import { useLocalStorage } from 'src/hooks/use-local-storage'
// utils
import { localStorageGetItem } from 'src/utils/storage-available'
//
import { SettingsValueProps } from '../types'
import { SettingsContext } from './settings-context'

// ----------------------------------------------------------------------

const STORAGE_KEY = 'settings_v2'

type SettingsProviderProps = {
  children: React.ReactNode
  defaultSettings: SettingsValueProps
}

export function SettingsProvider({ children, defaultSettings }: SettingsProviderProps) {
  const [state, update] = useLocalStorage<SettingsValueProps>(STORAGE_KEY, defaultSettings)

  const [openDrawer, setOpenDrawer] = useState(false)

  const isArabic = localStorageGetItem('i18nextLng') === 'ar'

  useEffect(() => {
    if (isArabic) {
      onChangeDirectionByLang('ar')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArabic])

  // Direction by lang
  const onChangeDirectionByLang = useCallback(
    (lang: string) => {
      update((prev) => ({ ...prev, themeDirection: lang === 'ar' ? 'rtl' : 'ltr' }))
    },
    [update]
  )

  const onUpdate = useCallback(
    (name: keyof SettingsValueProps, value: string | boolean) => {
      update((prev) => ({ ...prev, [name]: value }))
    },
    [update]
  )

  // Drawer
  const onToggleDrawer = useCallback(() => {
    setOpenDrawer((prev) => !prev)
  }, [])

  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false)
  }, [])

  const canReset = !isEqual(state, defaultSettings)

  const memoizedValue = useMemo(
    () => ({
      ...state,
      onUpdate,
      // Direction
      onChangeDirectionByLang,
      // Reset
      canReset,
      onReset: () => update(defaultSettings),
      // Drawer
      open: openDrawer,
      onToggle: onToggleDrawer,
      onClose: onCloseDrawer,
    }),
    [
      state,
      onUpdate,
      onChangeDirectionByLang,
      canReset,
      openDrawer,
      onToggleDrawer,
      onCloseDrawer,
      update,
      defaultSettings,
    ]
  )

  return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>
}
