import { useState, useEffect, useCallback } from 'react'
// @mui
import Collapse from '@mui/material/Collapse'
// routes
import { usePathname } from 'src/routes/hooks'
import { useActiveLink } from 'src/routes/hooks/use-active-link'
//
import { useLocation } from 'react-router'
import { NavListProps, NavConfigProps } from '../types'
import NavItem, { ViewAllNavItem } from './nav-item'

// ----------------------------------------------------------------------

type NavListRootProps = {
  data: NavListProps
  depth: number
  hasChild: boolean
  config: NavConfigProps
}

export default function NavList({ data, depth, hasChild, config }: NavListRootProps) {
  const pathname = usePathname()
  const { search } = useLocation()
  const queryParametersMatch = data.path.includes('?') ? data.path.replace(/.*\?/, '?').includes(search) : true
  const active = useActiveLink(data.path.replace(/\?.*$/, ''), hasChild) && queryParametersMatch

  const externalLink = data.path.includes('http')

  const [open, setOpen] = useState(active || data.alwaysStartOpen)

  useEffect(() => {
    if (!active && !data.alwaysStartOpen) {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(data.alwaysStartOpen ?? false)
  }, [data.alwaysStartOpen])

  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        open={open}
        active={active}
        externalLink={externalLink}
        onClick={handleToggle}
        config={config}
        variant={data.variant}
        clickableWithChildren={data.clickableWithChildren}
      />

      {hasChild && data.children !== undefined && (
        <Collapse in={open} unmountOnExit>
          <NavSubList data={data.children} depth={depth} config={config} />
          {data.viewAll && (
            <ViewAllNavItem
              item={data.viewAll}
              depth={depth + 1}
              open={open}
              active={active}
              externalLink={externalLink}
              config={config}
            />
          )}
        </Collapse>
      )}
    </>
  )
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  data: NavListProps[]
  depth: number
  config: NavConfigProps
}

function NavSubList({ data, depth, config }: NavListSubProps) {
  return (
    <>
      {data.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChild={!!list.children}
          config={config}
        />
      ))}
    </>
  )
}
