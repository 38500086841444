import { Theme } from '@mui/material/styles'
import { listClasses } from '@mui/material/List'
//
import { paper } from '../../css'

// ----------------------------------------------------------------------

export function popover(theme: Theme) {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          ...paper({ theme, dropdown: true, highContrastShadow: true }),
          // border: '1px solid rgba(0, 0, 0, 0.1)',
          // borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
          border: `1px solid ${theme.palette.divider}`,
          // borderTop: 'none',
          [`& .${listClasses.root}`]: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
    },
  }
}
