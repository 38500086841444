// @mui
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
// hooks
import { Typography } from '@mui/material'
import { useResponsive } from 'src/hooks/use-responsive'
// components
import { LogoFull } from 'src/components/logo'

// ----------------------------------------------------------------------

type Props = {
  image?: string
  children: React.ReactNode
}

export default function AuthModernLayout({ children, image }: Props) {
  const upMd = useResponsive('up', 'md')

  const renderContent = (
    <Stack
      sx={{
        marginY: 'auto',
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        minWidth: 320,
        px: { xs: 2, md: 8 },
      }}
    >
      <LogoFull
        disabledLink
        sx={{
          width: 150,
          mb: 6,
        }}
      />

      <Card
        sx={{
          py: { xs: 5, md: 0 },
          px: { xs: 3, md: 0 },
          boxShadow: { md: 'none' },
          overflow: { md: 'unset' },
          bgcolor: { md: 'background.default' },
        }}
      >
        {children}
      </Card>
    </Stack>
  )

  const renderSection = (
    <Stack flexGrow={1} color="common.white" sx={{ position: 'relative', textAlign: 'left' }}>
      <Typography
        variant="h1"
        sx={{
          zIndex: 1,
          marginY: 'auto',
          marginX: 'auto',
          whiteSpace: 'nowrap',
        }}
        // The style prop will override the variant's styles
        style={{
          // scale with the viewport
          // width: '100%',
          fontSize: 'max(2rem, 5vw)',
        }}
      >
        The Secure AI
        <br /> Assistant for the
        <br /> Office of the CEO
      </Typography>
      <Box
        component="img"
        alt="auth"
        src={image || '/assets/gradients/gradient-1.png'}
        sx={{
          top: 16,
          left: 16,
          objectFit: 'cover',
          position: 'absolute',
          width: 'calc(100% - 32px)',
          height: 'calc(100% - 32px)',
        }}
      />
    </Stack>
  )

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
        position: 'relative',
        '&:before': {
          width: 1,
          height: 1,
          zIndex: -1,
          content: "''",
          position: 'absolute',
          backgroundSize: 'cover',
          opacity: { xs: 0.24, md: 0 },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage: 'url(/assets/background/overlay_4.jpg)',
        },
      }}
    >
      {renderContent}

      {upMd && renderSection}
    </Stack>
  )
}
