// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 40,
  H_DESKTOP: 40,
  H_DESKTOP_OFFSET: 80 - 16,
  H_TRIAL: 48,
}

const SHOW_VERT_NAVBAR_SIZE: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md'

export const NAV = {
  SHOW_VERT_NAVBAR_SIZE,
  W_VERTICAL: 280,
  W_MINI: 88,
}
