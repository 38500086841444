import { m } from 'framer-motion'
// @mui
import { Theme, SxProps } from '@mui/material/styles'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Badge, { badgeClasses } from '@mui/material/Badge'
// components
import { useIntercom } from 'react-use-intercom'
import { Tooltip } from '@mui/material'
import Iconify from 'src/components/iconify'
import { varHover } from 'src/components/animate'
import { useAmbientIntercomContext } from 'src/components/intercom/ambient-intercom-context'

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>
}

export default function HelpButton({ sx }: Props) {
  const { show } = useIntercom()
  const { unreadMessages } = useAmbientIntercomContext()

  return (
    <Badge
      badgeContent={unreadMessages}
      color="error"
      variant="dot"
      sx={{
        [`& .${badgeClasses.badge}`]: {
          top: 8,
          right: 8,
        },
        ...sx,
      }}
    >
      <Tooltip title="Help">
        <Box
          component={m.div}
          transition={{
            duration: 12,
            ease: 'linear',
            repeat: Infinity,
          }}
        >
          <IconButton
            className="override-todesktop-drag"
            component={m.button}
            whileTap="tap"
            whileHover="hover"
            variants={varHover(1.05)}
            intercom-data-attribute="help-button"
            onClick={show}
            sx={{
              width: 40,
              height: 40,
            }}
          >
            <Iconify icon="ic:round-help" width={24} />
          </IconButton>
        </Box>
      </Tooltip>
    </Badge>
  )
}
