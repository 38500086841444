import { Helmet } from 'react-helmet-async'
// sections
import JwtPreauthorizedView from 'src/sections/auth/jwt/jwt-preauthorized-view'

// ----------------------------------------------------------------------

export default function PreauthorizedPage() {
  return (
    <>
      <Helmet>
        <title>Continue to Ambient</title>
      </Helmet>

      <JwtPreauthorizedView />
    </>
  )
}
