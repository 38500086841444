import { Handler } from 'mitt'
import { EventBusEvents } from 'src/components/event-bus/events'
import { Emitter } from '../components/event-bus/event-bus-provider'
import { AuthStateType } from './types'

export function clearCredentials() {
  localStorage.removeItem('creds')
}

export function setCredentials(accessToken: string | null, refreshToken: string | null) {
  localStorage.setItem('creds', JSON.stringify({ accessToken, refreshToken }))
}

export function getCredentials(): { accessToken: string | null; refreshToken: string | null } {
  return localStorage.getItem('creds')
    ? JSON.parse(localStorage.getItem('creds')!)
    : { accessToken: null, refreshToken: null }
}

export function getPersistantLoginSessionState(): AuthStateType {
  return localStorage.getItem('authState') ? JSON.parse(localStorage.getItem('authState')!) : undefined
}

export function setPersistantLoginSessionState(state: AuthStateType | undefined) {
  if (!state) {
    localStorage.removeItem('authState')
    return
  }
  localStorage.setItem('authState', JSON.stringify(state))
}

/**
 * This function returns a promise that will resolve when the user is re-authenticated.
 */
export function refreshLoginSession(): Promise<
  EventBusEvents['auth:reauth:complete'] | EventBusEvents['auth:reauth:failed']
> {
  const reauthEventName: keyof EventBusEvents = 'auth:reauth'
  const completeEventName: keyof EventBusEvents = 'auth:reauth:complete'
  const failedEventName: keyof EventBusEvents = 'auth:reauth:failed'

  // create a promise that will resolve when the user is re-authenticated
  const promise = new Promise<EventBusEvents['auth:reauth:complete'] | EventBusEvents['auth:reauth:failed']>(
    (resolve) => {
      const callback: Handler<EventBusEvents['auth:reauth:complete']> = ({ accessToken, refreshToken }) => {
        // remove the event listeners to avoid calling again and resolve the promise
        Emitter.off(completeEventName, callback)
        Emitter.off(failedEventName, failedCallback)
        resolve({ accessToken, refreshToken })
      }

      const failedCallback: Handler<EventBusEvents['auth:reauth:failed']> = () => {
        // remove the event listeners to avoid calling again and resolve the promise
        Emitter.off(completeEventName, callback)
        Emitter.off(failedEventName, failedCallback)
        resolve(undefined)
      }

      // Subscribe to the 'auth:reauth:complete' and 'auth:reauth:failed' events
      Emitter.on(completeEventName, callback)
      Emitter.on(failedEventName, failedCallback)
    }
  )

  // Now that the promise is created and the event listeners are subscribed, emit the 'auth:reauth' event
  Emitter.emit(reauthEventName)
  // return the promise that the caller can await on to know when the user is re-authenticated
  return promise
}
