// @mui
import Button from '@mui/material/Button'
// components
import { LinearProgress, Paper, Typography, keyframes, Stack } from '@mui/material'
import React from 'react'
import Iconify from 'src/components/iconify'
import { RecordingStatus } from 'src/types/meeting'
import { RouterLink } from 'src/routes/components'
import { paths } from 'src/routes/paths'

// ----------------------------------------------------------------------

const pulseKeyframes = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.4;
  }
`
interface MeetingControlsProps {
  recordingStatus: RecordingStatus
  onReturnToInviteForm?: VoidFunction
  onCloseDialog?: VoidFunction
  onStopRecording?: VoidFunction
}

export function RecordMeetingDialogControls({
  recordingStatus,
  onReturnToInviteForm: onShowForm,
  onCloseDialog,
  onStopRecording,
}: MeetingControlsProps) {
  let content: React.ReactNode
  let title: React.ReactNode
  let showProgress = false

  switch (recordingStatus) {
    case RecordingStatus.recordingRequested:
    case RecordingStatus.recordingBotJoining: {
      title = 'Your meeting assistant is on the way!'
      content = (
        <Typography variant="body2" px={1}>
          Ask the host of the meeting to click <strong>&quot;Admit&quot;</strong> so that we can join and record your
          meeting.
        </Typography>
      )
      showProgress = true
      break
    }

    case RecordingStatus.recordingBotInWaitingRoom: {
      title = (
        <>
          <Iconify
            color="warning.main"
            icon="material-symbols:warning-rounded"
            width={20}
            sx={{ mr: 1, translate: '0 20%' }}
          />
          Your meeting assistant is in the waiting room!
        </>
      )
      content = (
        <Typography variant="body2" px={1}>
          Ask the host of the meeting to click <strong>&quot;Admit&quot;</strong> so that we can join and record your
          meeting.
        </Typography>
      )
      showProgress = true
      break
    }

    case RecordingStatus.recordingBotInCallNotRecording: {
      title = (
        <>
          <Iconify
            color="warning.main"
            icon="material-symbols:warning-rounded"
            width={20}
            sx={{ mr: 1, translate: '0 20%' }}
          />
          Your meeting assistant isn&apos;t recording yet...
        </>
      )
      content = (
        <Typography variant="body2" px={1}>
          Ask the host to click <strong>&quot;Allow Recording&quot;</strong> so that we can record your meeting.
        </Typography>
      )
      showProgress = true
      break
    }

    case RecordingStatus.botRequestFailed: {
      title = (
        <>
          <Iconify
            color="error.main"
            icon="material-symbols:warning-rounded"
            width={20}
            sx={{ mr: 1, translate: '0 20%' }}
          />
          Something went wrong!
        </>
      )
      content = (
        <>
          <Typography variant="body2" px={1}>
            Ambient encountered an error while trying to join your meeting. Please try again.
          </Typography>
          <Button color="primary" variant="contained" sx={{ mt: 2 }} onClick={onShowForm}>
            Start another recording
          </Button>
        </>
      )
      break
    }

    case RecordingStatus.recordingStarted: {
      title = (
        <Stack direction="row" spacing={1}>
          <Iconify
            icon="mdi:record"
            width={20}
            sx={{
              color: 'primary.main',
              translate: '0 20%',
              animation: `${pulseKeyframes} 800ms infinite alternate ease-in-out`,
            }}
          />
          Recording in progress...
        </Stack>
      )
      content = (
        <>
          <Typography variant="body2" px={1}>
            Leave the note taking to us, at the end of the meeting we&apos;ll provide you with a summary.
          </Typography>
          <Button color="primary" variant="contained" sx={{ mt: 2 }} onClick={onStopRecording}>
            <Iconify icon="mdi:stop" width={20} sx={{ mr: 1 }} />
            Stop recording
          </Button>
        </>
      )
      break
    }

    case RecordingStatus.recordingStopRequested: {
      // Recording Stop Requested
      title = "We're wrapping up..."
      content = (
        <Typography variant="body2" px={1}>
          Your meeting assistant is now leaving the meeting! Summarization will begin as soon as it has left.
        </Typography>
      )
      showProgress = true
      break
    }

    case RecordingStatus.recordingCompleted: {
      // Recording completed
      title = 'Finished recording, go ahead and end the meeting.'
      content = (
        <>
          <Typography variant="body2" px={1}>
            Your recording and summary will soon be available in your company feed.
          </Typography>
          <Button color="primary" variant="contained" sx={{ mt: 2 }} onClick={onShowForm}>
            Start another recording
          </Button>
        </>
      )
      break
    }

    case RecordingStatus.botLeaveRequestFailed: {
      title = (
        <Stack direction="row" spacing={1}>
          <Iconify
            icon="mdi:warning-circle"
            width={20}
            sx={{
              color: 'primary.main',
              translate: '0 20%',
            }}
          />
          Something went wrong!
        </Stack>
      )
      content = (
        <>
          <Typography variant="body2" px={1}>
            We encountered an error while trying to leave your meeting. Please try again.
          </Typography>
          <Button color="primary" variant="contained" sx={{ mt: 2 }} onClick={onStopRecording}>
            <Iconify icon="mdi:stop" width={20} sx={{ mr: 1 }} />
            Stop recording
          </Button>
        </>
      )
      break
    }

    case RecordingStatus.botPoolExhausted: {
      title = (
        <Stack direction="row" spacing={1}>
          <Iconify
            icon="mdi:warning-circle"
            width={20}
            sx={{
              color: 'warning.main',
              translate: '0 20%',
            }}
          />
          Your meeting assistants are in high demand
        </Stack>
      )
      content = (
        <>
          <Typography variant="body2" px={1}>
            Please wait 30 seconds and try again.
          </Typography>
          <Typography variant="body2" color="text.secondary" p={1}>
            Tip:{' '}
            <em>Connect Ambient to your calendar to schedule recordings in advance and avoid future wait times.</em>
            <Button
              variant="text"
              color="primary"
              component={RouterLink}
              href={paths.dashboard.user.calendarSettings}
              onClick={onCloseDialog}
            >
              Learn more
            </Button>
          </Typography>
          <Button color="warning" variant="contained" onClick={onShowForm}>
            Try again
          </Button>
        </>
      )
      break
    }

    // this state should never be reached
    default: {
      break
    }
  }

  return (
    <Paper sx={{ p: 2, borderRadius: 2, backgroundColor: 'background.neutral' }}>
      <Stack spacing={3}>
        <Stack direction="row">
          <Typography variant="h6" px={1}>
            {title}
          </Typography>
        </Stack>
        {content}
        {showProgress && <LinearProgress sx={{ mt: 2 }} />}
      </Stack>
    </Paper>
  )
}
