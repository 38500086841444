// ----------------------------------------------------------------------

import { Post, PostSource, Tag } from 'src/__generated__/graphql'

export type IPostFilterValue = string

export type IPostFilters = {
  publish: string
}

// ----------------------------------------------------------------------

export type IPostHero = {
  isDraft: boolean
  postId: string
  title: string
  createdAt: Date
  canEdit: boolean
  postSource: PostSource
  author?:
    | {
        name: string
        avatarUrl: string
      }
    | undefined
}

export type IPostComment = {
  id: string
  name: string
  avatarUrl: string
  message: string
  postedAt: Date
  users: {
    id: string
    name: string
    avatarUrl: string
  }[]
  replyComment: {
    id: string
    userId: string
    message: string
    postedAt: Date
    tagUser?: string
  }[]
}

export type IPostItem = {
  id: string
  title: string
  tags: string[]
  publish: string
  content: string
  coverUrl: string
  metaTitle: string
  totalViews: number
  totalShares: number
  description: string
  totalComments: number
  totalFavorites: number
  metaKeywords: string[]
  metaDescription: string
  comments: IPostComment[]
  createdAt: Date
  favoritePerson: {
    name: string
    avatarUrl: string
  }[]
  author: {
    name: string
    avatarUrl: string
  }
}

export interface IPost extends Omit<Post, '__typename'> {
  isDraft?: boolean
  summaryId?: string
  projects?: {
    id: string
    name: string
  }[]
}

export interface FeedFilter {
  tags?: Tag[] | null
  postSource?: PostSource | null
  searchString?: string | null
  sharedWithMe?: boolean | null
  author: PostAuthorFilter
}

export enum PostAuthorFilter {
  companyWide = 'companyWide',
  myEmails = 'myEmails',
  myMeetings = 'myMeetings',
  sharedWithMe = 'sharedWithMe',
  drafts = 'drafts',
}
