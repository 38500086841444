// @mui
import { useTheme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'

import Iconify from 'src/components/iconify'
// theme
import { bgBlur } from 'src/theme/css'
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top'
import { useResponsive } from 'src/hooks/use-responsive'
// components
import Logo from 'src/components/logo'
import SvgColor from 'src/components/svg-color'
import { useSettingsContext } from 'src/components/settings'
//
import { useDesktopApp } from 'src/hooks/use-desktop-app'
import { useSubscriptionStatus } from 'src/hooks/use-subscription-status'
import { useBoolean } from 'src/hooks/use-boolean'
import { HEADER, NAV } from '../config-layout'
import {
  Searchbar,
  AccountPopover,
  // NotificationsPopover,
} from '../_common'
import HelpButton from '../_common/help-button'
import ContactUsCalendlyDialog from '../_common/nav-widgets/contact-us-calendly'

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction
}

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme()
  const { desktopTrafficLightWidth } = useDesktopApp()

  const settings = useSettingsContext()

  const isNavHorizontal = settings.themeLayout === 'horizontal'

  const isNavMini = settings.themeLayout === 'mini'

  const vertNavBreakpointUp = useResponsive('up', NAV.SHOW_VERT_NAVBAR_SIZE)

  const offset = useOffSetTop(HEADER.H_DESKTOP)

  const offsetTop = offset && !isNavHorizontal

  const { isTrial, subscriptionDaysLeft: trialDaysLeft, subscribed } = useSubscriptionStatus()

  const trialHeight = isTrial || !subscribed ? HEADER.H_TRIAL : 0
  const showCalendly = useBoolean(false)

  const renderContent = (
    <>
      {vertNavBreakpointUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!vertNavBreakpointUp && (
        <Box
          pl={1}
          sx={{
            alignContent: 'center',
            pointerEvents: 'all',
            py: 0.25,
            borderBottomRightRadius: theme.spacing(2),
            backgroundColor: theme.palette.background.default,
          }}
        >
          <IconButton
            className="override-todesktop-drag"
            onClick={onOpenNav}
            // move the icon to the right to account for the traffic light on desktop app
            sx={{ ml: `${desktopTrafficLightWidth}px` }}
          >
            <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
          </IconButton>
        </Box>
      )}

      <Stack
        flexGrow={0}
        ml="auto"
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
        sx={{
          pointerEvents: 'all',
          ...bgBlur({
            color: theme.palette.background.default,
          }),
          px: 0.25,
          py: 0.25,
          borderBottomLeftRadius: theme.spacing(2),
        }}
      >
        {/* <NotificationsPopover /> */}

        <Searchbar />
        <HelpButton />
        <AccountPopover />
      </Stack>
    </>
  )

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE + trialHeight,
        zIndex: theme.zIndex.appBar + 1,
        pointerEvents: 'none',
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(vertNavBreakpointUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          // this pushes the header down for no good reason
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET + trialHeight,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET + trialHeight,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      {isTrial && trialDaysLeft > -1 && (
        <Box
          sx={{
            pointerEvents: 'all',
            backgroundImage: "url('/assets/gradients/gradient-1.png')",
            minHeight: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          ✨ {trialDaysLeft} day{trialDaysLeft > 1 ? 's' : ''} of your free trial remaining ✨
          <Button
            className="override-todesktop-drag"
            endIcon={<Iconify icon="solar:arrow-right-linear" />}
            onClick={showCalendly.onTrue}
          >
            Contact us to start a plan
          </Button>
        </Box>
      )}
      {!isTrial && !subscribed && (
        <Box
          sx={{
            pointerEvents: 'all',
            backgroundImage: "url('/assets/gradients/gradient-1.png')",
            minHeight: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Your free trial has ended and you can no longer record meetings
          <Button
            className="override-todesktop-drag"
            endIcon={<Iconify icon="solar:arrow-right-linear" />}
            onClick={showCalendly.onTrue}
          >
            Contact us to start a plan
          </Button>
        </Box>
      )}
      <ContactUsCalendlyDialog open={showCalendly.value} onClose={showCalendly.onFalse} />
      <Toolbar
        sx={{
          height: 1,
          padding: '0 !important',
          alignItems: 'start',
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  )
}
