import { useEffect, useState } from 'react'
import { differenceInCalendarDays } from 'date-fns'
import { useAuthContext } from 'src/auth/hooks'

interface SubscriptionStatus {
  subscribed: boolean
  endDate?: Date
  isTrial: boolean
  subscriptionDaysLeft: number
}

/**
 * This hook is a convenience wrapper around the auth context to provide
 * a simple way to check if a user is subscribed or not and the number of
 * days left in their subscription. It used to by other components to
 * determine if they should show a paywall or not.
 *
 * TODO: Consider refactioring this to use the auth context directly
 */
export function useSubscriptionStatus() {
  const { roles, subscriptionStatus } = useAuthContext()
  const [subState, setSubState] = useState<SubscriptionStatus>({
    // if this is disabled by default there may be flickering
    subscribed: roles.includes('subscribed'),
    endDate: undefined,
    isTrial: false,
    subscriptionDaysLeft: -1,
  })

  useEffect(() => {
    const isSubscribed = roles.includes('subscribed')
    if (!isSubscribed) {
      setSubState({
        subscribed: isSubscribed,
        endDate: undefined,
        isTrial: false,
        subscriptionDaysLeft: -1,
      })
    } else {
      const now = new Date()
      const daysLeft = subscriptionStatus?.endDate
        ? differenceInCalendarDays(new Date(subscriptionStatus.endDate), now)
        : 0

      setSubState({
        subscribed: isSubscribed,
        endDate: subscriptionStatus?.endDate,
        isTrial: subscriptionStatus?.isTrial ?? false,
        subscriptionDaysLeft: daysLeft < 0 ? -1 : daysLeft,
      })
    }
  }, [roles, subscriptionStatus])

  return subState
}
