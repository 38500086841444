import Box from '@mui/material/Box'
// sections
import { UnexpectedErrorView } from 'src/sections/error'

// ----------------------------------------------------------------------

export default function LastResortErrorBoundaryFlalbackPage() {
  return (
    <Box sx={{ p: 5, textAlign: 'center' }}>
      <UnexpectedErrorView />
    </Box>
  )
}
