// routes
import { paths } from 'src/routes/paths'

// PATHS
export const PATH_AFTER_LOGIN = paths.dashboard.home // as '/dashboard'

// GRAPHQL
export const GRAPHQL_URL = '/api/graphql'
export const AMBIENT_API_URL = `https://${import.meta.env.VITE_AMBIENT_API_HOSTNAME!}`
export const AMBIENT_API_PROXY_PATH = '/api'
export const GRAPHQL_WS_URL = `wss://${import.meta.env.VITE_AMBIENT_API_HOSTNAME!}/graphql/subscription`

// INTEGRATIONS
export const GOOGLE_CALENDAR_CLIENT_ID = import.meta.env.VITE_GOOGLE_CALENDAR_CLIENT_ID
export const OUTLOOK_CALENDAR_CLIENT_ID = import.meta.env.VITE_OUTLOOK_CALENDAR_CLIENT_ID

// LOGIN
export const GOOGLE_OAUTH_CLIENT_ID = import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID

// MISCELLANEOUS
export const AMBIENT_WEBSITE_URL = `${import.meta.env.VITE_PUBLIC_URL!}`
export const DEPLOY_ENV = import.meta.env.VITE_DEPLOY_ENV
export const ENABLED_FEATURE_FLAGS = import.meta.env.VITE_ENABLED_FEATURE_FLAGS

// SERVICES
export const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
export const SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT
export const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN
export const MIXPANEL_DEBUG = import.meta.env.VITE_MIXPANEL_DEBUG === 'true'
