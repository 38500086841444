import { useSubscribe } from 'src/hooks/use-event-bus'
import { ACTION_ITEM_TABLE_PERSISTANT_STATE_KEYS, FilterSettings, PersistantCacheKey } from './types'

function getCacheFilterKey(suffix: string, userId: string) {
  return `aitble-${userId}-${suffix}`
}

/**
 * Get the persisted filter settings for the user
 * @param customKey Used to differentiate between different filter settings for different tables such as the dashboard vs post view
 * @param userId
 * @returns
 */
export function getPersistedFilterSettings(customKey: PersistantCacheKey, userId: string): FilterSettings | undefined {
  const cacheFilterKey = getCacheFilterKey(customKey, userId)
  const cacheFilter = localStorage.getItem(cacheFilterKey)
  if (cacheFilter) {
    try {
      return JSON.parse(cacheFilter) as FilterSettings
    } catch (err) {
      // prevent app from crashing if there is an error parsing the cached filter
      console.error('Error parsing cached filter', err)
    }
  }
  return undefined
}

/**
 * Set the persisted filter settings for the user
 * @param customKey Used to differentiate between different filter settings for different tables such as the dashboard vs post view
 * @param userId
 * @param filterSettings
 */
export function setPersistedFilterSettings(
  customKey: PersistantCacheKey,
  userId: string,
  filterSettings: FilterSettings
) {
  const cacheFilterKey = getCacheFilterKey(customKey, userId)
  localStorage.setItem(cacheFilterKey, JSON.stringify(filterSettings))
}

/**
 * Called on logout to clear all persisted action item filter settings for the user
 * @param userId
 */
export function clearAllPersistedFilterSettings(userId: string) {
  // loop through local storage and remove all persisted filters for the user
  ACTION_ITEM_TABLE_PERSISTANT_STATE_KEYS.forEach((key) => {
    const cacheFilterKey = getCacheFilterKey(key, userId)
    localStorage.removeItem(cacheFilterKey)
  })
}

/**
 * This component will handle cleaning up state when the user logs out or the session expires.
 */
export function ActionItemTableAuthEventListener() {
  /**
   * To make the switching between the delegate and the main user more seamless, we want to copy the
   * filter settings from the main user to the delegate user or vice versa. This prevents the user
   * from having to reapply the filter settings when switching between users.
   */
  useSubscribe('auth:switchuser', ({ fromUserId, toUserId, delegateSession }) => {
    if (delegateSession) {
      ACTION_ITEM_TABLE_PERSISTANT_STATE_KEYS.forEach((key) => {
        const filterSettings = getPersistedFilterSettings(key, fromUserId)
        if (filterSettings) {
          if (filterSettings.assignedToUserId) {
            filterSettings.assignedToUserId = `usr:${toUserId}`
          }
          setPersistedFilterSettings(key, toUserId, filterSettings)
        }
      })
    }
  })

  // when a user logs out remove persisted filter
  useSubscribe('auth:logout', ({ userId }) => {
    clearAllPersistedFilterSettings(userId)
  })

  return null
}
