import { loadIcons } from '@iconify/react'

// ----------------------------------------------------------------------
// Download icons so they are available when the component is rendered to avoid flickering
export function preloadTablerIcons() {
  loadIcons([
    'tabler:arrow-bar-to-right',
    'tabler:arrow-up',
    'tabler:arrows-split-2',
    'tabler:brand-slack',
    'tabler:calendar-event',
    'tabler:calendar',
    'tabler:circle-check',
    'tabler:diamonds-filled',
    'tabler:dots',
    'tabler:file-text-ai',
    'tabler:fill-arrow-right',
    'tabler:folder-off',
    'tabler:folder',
    'tabler:logs',
    'tabler:messages',
    'tabler:movie-off',
    'tabler:news',
    'tabler:note',
    'tabler:notes-off',
    'tabler:sun',
    'tabler:tags',
    'tabler:trash',
    'tabler:user-circle',
    'tabler:video',
  ])
}
