import { lazy, Suspense } from 'react'
import { Outlet, RouteObject } from 'react-router-dom'
// auth
import { GuestGuard } from 'src/auth/guard'
// layouts
import AuthModernLayout from 'src/layouts/auth/modern'
// components
import { SplashScreen } from 'src/components/loading-screen'
import PreauthorizedPage from 'src/pages/auth/jwt/preauthroized'

// ----------------------------------------------------------------------

// AMPLIFY

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'))
const JwtLogoutPage = lazy(() => import('src/pages/auth/jwt/logout'))
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'))
const authJwt: RouteObject = {
  path: 'jwt',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthModernLayout>
          <JwtLoginPage />
        </AuthModernLayout>
      ),
    },
    {
      path: 'logout',
      element: (
        <AuthModernLayout>
          <JwtLogoutPage />
        </AuthModernLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthModernLayout>
          <JwtRegisterPage />
        </AuthModernLayout>
      ),
    },
    {
      path: 'preauthorized/:code',
      element: (
        <AuthModernLayout>
          <PreauthorizedPage />
        </AuthModernLayout>
      ),
    },
  ],
}

// this matches the path for the auth routes in the old frontend to make migration easier
export const authCompatibilityRoutes: RouteObject[] = [
  {
    path: 'login/preauthorized/:code',
    element: (
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <AuthModernLayout>
            <PreauthorizedPage />
          </AuthModernLayout>
        </Suspense>
      </GuestGuard>
    ),
  },
]

export const authRoutes: RouteObject[] = [
  {
    path: 'auth',
    children: [authJwt],
  },
]
