import { ErrorBoundary } from 'react-error-boundary'
import { Navigate, Outlet, useLocation, useRoutes } from 'react-router-dom'
import { useEffect } from 'react'
import CompactLayout from 'src/layouts/compact'
import RouterErrorBoundaryPage from 'src/pages/router-error-boundary-page'
import { useAnalytics } from 'src/components/analytics'
import { errorRoutes } from './error'
import { authCompatibilityRoutes, authRoutes } from './auth'
import { dashboardRoutes } from './dashboard'
import { paths } from '../paths'
import { shareRoutes } from './share'
import { integrationRoutes } from './integration'

// ----------------------------------------------------------------------
export default function Router() {
  const routes = useRoutes([
    {
      element: (
        <NavigationListener>
          <Outlet />
        </NavigationListener>
      ),
      children: [
        // SET INDEX PAGE WITH HOME PAGE
        {
          path: '/',
          element: <Navigate to={paths.dashboard.home} replace />,
        },

        // Backward compatibility auth routes
        ...authCompatibilityRoutes,
        // Auth routes
        ...authRoutes,
        // Dashboard routes
        ...dashboardRoutes,
        // Main routes
        ...errorRoutes,
        // public sharing routes
        ...shareRoutes,
        // integration routes
        ...integrationRoutes,

        // No match 404
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
  ])

  return <ErrorBoundary FallbackComponent={ErrorFallback}>{routes}</ErrorBoundary>
}

function NavigationListener({ children }: { children: React.ReactNode }) {
  const { trackPageView } = useAnalytics()
  const { pathname, search } = useLocation()

  useEffect(() => {
    trackPageView()
  }, [pathname, search, trackPageView])

  return children
}

function ErrorFallback({ error }: any) {
  // Handle failed lazy loading of a JS/CSS chunk.
  useEffect(() => {
    // const chunkFailedMessage = /Loading chunk [\d]+ failed/
    if (error?.message) {
      if (!getWithExpiry('error_500')) {
        setWithExpiry('error_500', 'true', 2000)
        setTimeout(() => {
          window.location.reload()
        })
      }
    }
  }, [error])

  // prevent screen flashes by waiting for the error to be set
  if (!getWithExpiry('error_500')) {
    return <div>&nbsp;</div>
  }

  return (
    <CompactLayout>
      <RouterErrorBoundaryPage />
    </CompactLayout>
  )
}

export function setWithExpiry(key: string, value: string, ttl: number) {
  const item = {
    value,
    expiry: new Date().getTime() + ttl,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export function getWithExpiry(key: string) {
  const itemString = window.localStorage.getItem(key)
  if (!itemString) return null

  const item = JSON.parse(itemString)
  const isExpired = new Date().getTime() > item.expiry

  if (isExpired) {
    localStorage.removeItem(key)
    return null
  }

  return item.value
}
