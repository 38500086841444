import { forwardRef } from 'react'
// @mui
import { useTheme } from '@mui/material/styles'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import ListItemText, { ListItemTextProps } from '@mui/material/ListItemText'
// routes
import Badge from '@mui/material/Badge'
import { RouterLink } from 'src/routes/components'
//
import { Box } from '@mui/material'
import Iconify from '../../iconify'
//
import { NavItemProps, NavConfigProps } from '../types'
import { StyledItem, StyledIcon } from './styles'

// ----------------------------------------------------------------------

type Props = NavItemProps & {
  config: NavConfigProps
}

const NavItem = forwardRef<HTMLDivElement, Props>(
  ({ item, depth, open, active, externalLink, config, ...other }, ref) => {
    const theme = useTheme()

    const { title, path, icon, children, disabled, caption, roles, info, badgeText } = item

    const subItem = depth !== 1

    let primaryTypographyProps: ListItemTextProps['primaryTypographyProps'] = {
      fontSize: 10,
      lineHeight: '16px',
      textAlign: 'center',
      textTransform: 'capitalize',
      fontWeight: active ? 'fontWeightBold' : 'fontWeightSemiBold',
      ...(subItem && {
        textAlign: 'unset',
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        fontWeight: active ? 'fontWeightSemiBold' : 'fontWeightMedium',
      }),
    }
    if (other.variant === 'small') {
      primaryTypographyProps = {
        display: 'none',
      }
    }

    const renderContent = (
      <StyledItem
        disableGutters
        ref={ref}
        open={open}
        depth={depth}
        active={active}
        disabled={disabled}
        config={config}
        {...other}
      >
        {icon && (
          <Badge color="error" variant={badgeText ? 'dot' : 'invisible'}>
            <StyledIcon
              size={config.iconSize}
              sx={{
                ...(subItem && { mr: 1.5 }),
              }}
            >
              {icon}
            </StyledIcon>
          </Badge>
        )}

        {!(config.hiddenLabel && !subItem) && (
          <ListItemText
            sx={{
              width: 1,
              flex: 'unset',
              ...(!subItem && {
                px: 0.5,
                mt: 0.5,
              }),
            }}
            primary={title}
            primaryTypographyProps={primaryTypographyProps}
          />
        )}

        {info && (
          <Box component="span" sx={{ ml: 1, lineHeight: 0 }}>
            {info}
          </Box>
        )}

        {caption && (
          <Tooltip title={caption} arrow placement="right">
            <Iconify
              width={16}
              icon="eva:info-outline"
              sx={{
                color: 'text.disabled',
                ...(!subItem && {
                  top: 11,
                  left: 6,
                  position: 'absolute',
                }),
              }}
            />
          </Tooltip>
        )}

        {!!children && children.length > 0 && (
          <Iconify
            width={16}
            icon="eva:arrow-ios-forward-fill"
            sx={{
              top: 11,
              right: 6,
              position: 'absolute',
            }}
          />
        )}
      </StyledItem>
    )

    // Hidden item by role
    if (roles && !roles.includes(`${config.currentRole}`)) {
      return null
    }

    // External link
    if (externalLink)
      return (
        <Link
          intercom-data-attribute={title.toLowerCase()}
          href={path}
          target="_blank"
          rel="noopener"
          underline="none"
          sx={{
            width: 1,
            ...(disabled && {
              cursor: 'default',
            }),
          }}
        >
          {renderContent}
        </Link>
      )

    if (other.variant === 'small') {
      return null
    }
    // Default
    return (
      <Link
        intercom-data-attribute={title.toLowerCase()}
        component={RouterLink}
        href={path}
        underline="none"
        sx={{
          width: 1,
          ...(disabled && {
            cursor: 'default',
          }),
        }}
      >
        {renderContent}
      </Link>
    )
  }
)

export default NavItem
