import { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
//
import App from './App'
import { ErrorContextProvider } from './components/error-context'
import { AnalyticsProvider } from './components/analytics'
// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <ErrorContextProvider>
    <AnalyticsProvider>
      <HelmetProvider>
        <BrowserRouter>
          <Suspense>
            <App />
          </Suspense>
        </BrowserRouter>
      </HelmetProvider>
    </AnalyticsProvider>
  </ErrorContextProvider>
)
