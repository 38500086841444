// @mui
import { useTheme } from '@mui/material/styles'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
// hooks
import { useResponsive } from 'src/hooks/use-responsive'
// theme
import { bgBlur } from 'src/theme/css'
// components
import Iconify from 'src/components/iconify'
import { useSettingsContext } from 'src/components/settings'
//
import { NAV } from '../config-layout'

// ----------------------------------------------------------------------

export default function NavToggleButton({ sx, ...other }: IconButtonProps) {
  const theme = useTheme()
  const settings = useSettingsContext()

  const showToggle = useResponsive('up', NAV.SHOW_VERT_NAVBAR_SIZE)

  if (!showToggle) {
    return null
  }

  return (
    <IconButton
      className="override-todesktop-drag"
      intercom-data-attribute="toggle-navigation"
      size="small"
      onClick={() => settings.onUpdate('themeLayout', settings.themeLayout === 'vertical' ? 'mini' : 'vertical')}
      sx={{
        p: 0.5,
        top: 20,
        position: 'fixed',
        left: NAV.W_VERTICAL - 12,
        zIndex: theme.zIndex.appBar + 1,
        border: `dashed 1px ${theme.palette.nav.divider}`,
        ...bgBlur({ opacity: 0.48, color: theme.palette.nav.background }),
        '&:hover': {
          bgcolor: 'background.default',
        },
        ...sx,
      }}
      {...other}
    >
      <Iconify
        width={16}
        icon={settings.themeLayout === 'vertical' ? 'eva:arrow-ios-back-fill' : 'eva:arrow-ios-forward-fill'}
      />
    </IconButton>
  )
}
