// components
import { SplashScreen } from 'src/components/loading-screen'
//
import { useMemo } from 'react'
import type { JWTContextType } from 'src/auth/types'
import { AuthContext } from './auth-context'
import { GlobalAuthEventListeners } from './global-auth-event-listeners'

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
}

export function AuthConsumer({ children }: Props) {
  return (
    <AuthContext.Consumer>
      {(auth) => <AuthConsumerChildrenWrapper auth={auth}>{children}</AuthConsumerChildrenWrapper>}
    </AuthContext.Consumer>
  )
}

/**
 * This wraper ensures that the global auth listeners are present even if we are loading
 */
function AuthConsumerChildrenWrapper({ children, auth }: { children: React.ReactNode; auth: JWTContextType }) {
  const result = useMemo(
    () => (
      <>
        <GlobalAuthEventListeners />
        {auth.loading ? <SplashScreen /> : children}
      </>
    ),
    [children, auth.loading]
  )
  return result
}
