// @mui
import Box from '@mui/material/Box'
// hooks
import { useBoolean } from 'src/hooks/use-boolean'
import { useResponsive } from 'src/hooks/use-responsive'
// components
import { useSettingsContext } from 'src/components/settings'
//
import Main from './main'
import Header from './header'
import NavMini from './nav-mini'
import NavVertical from './nav-vertical'
import { NAV } from '../config-layout'

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
}

export default function DashboardLayout({ children }: Props) {
  const settings = useSettingsContext()
  const showVertNav = useResponsive('up', NAV.SHOW_VERT_NAVBAR_SIZE)
  const nav = useBoolean()
  const isMini = settings.themeLayout === 'mini'
  const renderNavMini = <NavMini />

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          {showVertNav ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
      </>
    )
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>{children}</Main>
      </Box>
    </>
  )
}
