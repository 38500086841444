import useMediaQuery from '@mui/material/useMediaQuery'
import { useCallback } from 'react'

/**
 * Whether the system theme is dark
 */
export function useSystemThemeMode() {
  const prefersDark = useMediaQuery('(prefers-color-scheme: dark)')

  const currentThemeMode = useCallback(
    (themeMode: 'light' | 'dark' | 'system') => {
      if (themeMode === 'system') {
        return prefersDark ? 'dark' : 'light'
      }

      return themeMode
    },
    [prefersDark]
  )

  return {
    systemThemeMode: prefersDark ? 'dark' : 'light',
    currentThemeMode,
  }
}
