import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
// components
import { CircularProgress, DialogContent, InputAdornment, Stack, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import Iconify from 'src/components/iconify'
import { getValidMeetingLinkType } from 'src/utils/validation'
import { useInFlightRecording } from 'src/hooks/use-inflight-recording'
import { RecordingStatus } from 'src/types/meeting'
import { useBoolean } from 'src/hooks/use-boolean'
import { inviteBotToMeeting, removeMeetingBot } from 'src/api/rest'
import uuidv4 from 'src/utils/uuidv4'
import { useErrorContext } from 'src/components/error-context/error-context'
import { useAnalytics } from 'src/components/analytics'
import { useSubscriptionStatus } from 'src/hooks/use-subscription-status'
import { RecordMeetingDialogControls } from './record-meeting-dialog-controls'
import FreeTrialEndedBanner from './free-trial-ended-banner'

// ----------------------------------------------------------------------

/**
 * This form is a state machine with the following states:
 */
enum FSMState {
  // the initial state showing the invite form
  invite = 'invite',

  // recall our our own API rejected the request to join the meeting
  // we should show an error message and an action to lead the user back to the invite form
  inviteError = 'inviteError',

  // the bot is waiting for the host to admit it or enable permissions
  joining = 'joining',

  // the bot has joined the meeting and is recording or is waiting for the host to admit it
  recording = 'recording',

  // the bot has finished recording and is in the process of leaving the meeting
  stopping = 'stopping',

  // the recording is done and the bot is leaving or has left the meeting.
  // the form should show a view to return to the invite form
  recordingDone = 'recordingDone',
}

// these messages move the state machine forward to help prevent ambiguous states
// that can occur when reacting to state changes of our data and user input
type FSMMessage =
  // the user has submitted the invite form
  | { event: 'request_recording'; error: boolean; recordingStatus: RecordingStatus }

  // the status of the in flight recording has changed
  | { event: 'change_status'; status: RecordingStatus }

  // the user has stopped the recording
  | { event: 'stop' }

  // the in flight recording has been removed
  | { event: 'recording_not_available' }

  // the user has clicked a button to restart the process
  | { event: 'restart' }

type Props = {
  open: boolean
  onClose: VoidFunction
}

export default function MeetingBotControlsDialog({ open, onClose }: Props) {
  const { track } = useAnalytics()
  const { logError } = useErrorContext()
  const { subscribed } = useSubscriptionStatus()
  const [state, setState] = useState<FSMState>(FSMState.invite)
  const [recordingStatus, setRecordingStatus] = useState<RecordingStatus>(RecordingStatus.unknown)
  const { inFlightRecording } = useInFlightRecording({ includeScheduled: false, subscribeToMore: true })
  const showForm = useBoolean(true)

  // form data management
  const Schema = Yup.object().shape({
    meetingUrl: Yup.string()
      .required('Meeting url is required')
      .url('Please enter valid url')
      .test('is-valid-url', 'The link provided is not a valid meeting link.', (value) => {
        const meetingType = getValidMeetingLinkType(value)
        return meetingType !== undefined
      }),
  })

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      meetingUrl: '',
    },
  })

  const {
    handleSubmit,
    resetField,
    getValues,
    formState: { isSubmitting },
  } = methods

  // reset form when dialog is opened
  useEffect(() => {
    if (open && !getValues('meetingUrl')) {
      resetField('meetingUrl')
    }
  }, [getValues, open, resetField])

  // transition to a new state
  // it can be helpful to log the state transitions to help debug
  const transitionToState = useCallback((newState: FSMState) => {
    setState(newState)
  }, [])

  // Process messages to move the state machine forward
  // Events are triggered by other state changes and help prune bad states
  // by only allowing movement to new states based on the current state
  const processMessage = useCallback(
    (message: FSMMessage) => {
      if (state === FSMState.invite) {
        // handle moving from invite to error or joining state
        if (message.event === 'request_recording') {
          setRecordingStatus(message.recordingStatus)
          transitionToState(message.error ? FSMState.inviteError : FSMState.joining)
          showForm.onFalse()
        } else if (message.event === 'change_status') {
          setRecordingStatus(message.status)
          if (
            message.status === RecordingStatus.recordingRequested ||
            message.status === RecordingStatus.recordingBotJoining ||
            message.status === RecordingStatus.recordingBotInWaitingRoom ||
            message.status === RecordingStatus.recordingBotInCallNotRecording
          ) {
            transitionToState(FSMState.joining)
            showForm.onFalse()
          } else if (message.status === RecordingStatus.recordingStarted) {
            transitionToState(FSMState.recording)
            showForm.onFalse()
          } else if (
            message.status === RecordingStatus.recordingStopRequested ||
            message.status === RecordingStatus.botLeaveRequestFailed
          ) {
            transitionToState(FSMState.stopping)
            showForm.onFalse()
          } else if (message.status === RecordingStatus.recordingCompleted) {
            transitionToState(FSMState.recordingDone)
            showForm.onFalse()
          } else {
            transitionToState(FSMState.invite)
            showForm.onTrue()
          }
        }
      } else if (state === FSMState.inviteError) {
        // handle moving from invite error to invite state
        if (message.event === 'restart') {
          setRecordingStatus(RecordingStatus.unknown)
          transitionToState(FSMState.invite)
          showForm.onTrue()
        }
      } else if (state === FSMState.joining) {
        // handle moving between status messages or moving to recording state
        if (message.event === 'change_status') {
          setRecordingStatus(message.status)
          if (message.status === RecordingStatus.recordingStarted) {
            transitionToState(FSMState.recording)
          }
        }
      } else if (state === FSMState.recording) {
        // handle moving between status messages or moving to recording done state
        if (message.event === 'change_status') {
          setRecordingStatus(message.status)
          // if the bot was removed from the meeting, move to recording done state
          if (message.status === RecordingStatus.recordingCompleted) {
            transitionToState(FSMState.stopping)
          }
        } else if (message.event === 'stop' || message.event === 'recording_not_available') {
          // if a stop was requested or the bot was removed from the meeting
          setRecordingStatus(RecordingStatus.recordingStopRequested)
          transitionToState(FSMState.stopping)
        }
      } else if (state === FSMState.stopping) {
        // handle moving between status messages or moving to recording done state
        if (message.event === 'recording_not_available') {
          setRecordingStatus(RecordingStatus.recordingCompleted)
          transitionToState(FSMState.recordingDone)
        }
      } else if (state === FSMState.recordingDone) {
        // handle moving from recording done to invite state
        if (message.event === 'restart') {
          setRecordingStatus(RecordingStatus.unknown)
          transitionToState(FSMState.invite)
          showForm.onTrue()
        }
      }
    },
    [showForm, state, transitionToState]
  )

  // respond to status changes for the inflight recording
  useEffect(() => {
    if (inFlightRecording) {
      processMessage({ event: 'change_status', status: inFlightRecording.recordingStatus as RecordingStatus })
    } else {
      processMessage({ event: 'recording_not_available' })
    }
  }, [inFlightRecording, processMessage])

  // submitting the form
  const onSubmit = handleSubmit(
    useCallback(
      async (formData) => {
        track('Click', { element: 'invite_recorder_to_meeting' })
        try {
          await inviteBotToMeeting(formData.meetingUrl, uuidv4())
          processMessage({
            event: 'request_recording',
            error: false,
            recordingStatus: RecordingStatus.recordingRequested,
          })
        } catch (error) {
          logError(error)
          if (error.response?.status === 503) {
            processMessage({
              event: 'request_recording',
              error: true,
              recordingStatus: RecordingStatus.botPoolExhausted,
            })
          } else {
            processMessage({
              event: 'request_recording',
              error: true,
              recordingStatus: RecordingStatus.botRequestFailed,
            })
          }
        }
      },
      [logError, processMessage, track]
    )
  )

  const handleRecordingStop = useCallback(async () => {
    try {
      if (inFlightRecording?.meetingBotId) {
        await removeMeetingBot(inFlightRecording?.meetingBotId)
        processMessage({ event: 'stop' })
      }
    } catch (error) {
      logError(error)
    }
  }, [inFlightRecording?.meetingBotId, logError, processMessage])

  const handleReturnToInviteForm = useCallback(() => {
    processMessage({ event: 'restart' })
  }, [processMessage])

  const renderForm = (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <RHFTextField
        name="meetingUrl"
        label="Meeting URL"
        autoFocus
        disabled={isSubmitting || !!inFlightRecording || !subscribed}
        InputProps={{
          placeholder: 'https://zoom.us/j/1234567890',
          endAdornment: !inFlightRecording && (
            <InputAdornment position="end">
              <Button variant="contained" color="primary" disabled={isSubmitting || !subscribed} onClick={onSubmit}>
                {isSubmitting ? <CircularProgress size={20} /> : 'Invite'}
              </Button>
            </InputAdornment>
          ),
        }}
      />
    </FormProvider>
  )

  const renderControls = (
    <RecordMeetingDialogControls
      onReturnToInviteForm={handleReturnToInviteForm}
      onCloseDialog={onClose}
      onStopRecording={handleRecordingStop}
      recordingStatus={recordingStatus}
    />
  )

  return (
    <Dialog
      PaperProps={{
        sx: (theme) => ({
          maxWidth: theme.breakpoints.values.md,
          width: theme.breakpoints.values.sm,
          overflow: 'auto',
        }),
      }}
      sx={{ overflow: 'auto' }}
      open={open}
      onClose={onClose}
    >
      <DialogActions sx={(theme) => ({ p: 2, minHeight: 0, background: theme.palette.background.neutral })}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Invite Ambient to your meeting
        </Typography>
        {/* <Button
          onClick={() =>
            setRecordingStatus((prev) => {
              const index = Object.values(RecordingStatus).findIndex((status) => status === prev)
              const next = index + 1

              const remainder = next % Object.values(RecordingStatus).length

              return Object.values(RecordingStatus)[remainder === 0 ? 1 : next]
            })
          }
        >
          simulate next state
        </Button> */}
        <Button variant="text" sx={{ minWidth: 0 }} onClick={onClose}>
          Close
        </Button>
      </DialogActions>
      {!subscribed && (
        <Stack mx={1} mt={1}>
          <FreeTrialEndedBanner />
        </Stack>
      )}
      <DialogContent sx={{ p: 2 }}>
        <Stack p={2} spacing={2}>
          <Typography>
            {`Enter a meeting link below and we'll automatically record, transcribe, and summarize your meeting.`}
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Typography component="div" variant="caption" sx={{ my: 0.5, color: 'text.secondary' }}>
              Supported Platforms
            </Typography>
            <Iconify icon="logos:zoom" width={45} mt={-1} />
            <Iconify icon="logos:microsoft-teams" width={26} />
            <Iconify icon="logos:google-meet" width={26} />
          </Stack>
          {showForm.value && renderForm}
          {!showForm.value && renderControls}
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
