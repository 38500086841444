// ----------------------------------------------------------------------

import { useMemo } from 'react'
import mitt from 'mitt'
import { EventBusProviderContext } from './event-bus-context'

/**
 * The event bus emitter is a singleton that can be used to emit events across the app
 *
 * If you are in a component, you can use the `useEventBusEmitter` hook to get the emitter.
 * If you are in a non-component file, you can import the `Emitter` singleton directly.
 */
export const Emitter = mitt<Record<string, any>>()

/**
 * A provider that wraps the app and provides the event bus emitter to all components
 */
export default function EventBusProvider({ children }: { children: React.ReactNode }) {
  const providerState = useMemo(() => {
    console.debug('EventBusProvider initialized')
    Emitter.all.clear()
    return {
      emitter: Emitter,
    }
  }, [])
  return <EventBusProviderContext.Provider value={providerState}>{children}</EventBusProviderContext.Provider>
}
