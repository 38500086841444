import { createContext, useContext } from 'react'
import { Emitter } from 'mitt'

export type EventBusState<T extends Record<string, any> = Record<string, any>> = {
  emitter: Emitter<T>
}

export const EventBusProviderContext = createContext({} as EventBusState)

/**
 * Get the event bus context
 */
export function useEventBusContext<T extends Record<string, any>>() {
  const context = useContext(EventBusProviderContext)

  if (!context) throw new Error('useEventBusContext must be use inside EventBusProvider')

  return context as EventBusState<T>
}
