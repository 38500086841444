import Typography from '@mui/material/Typography'
import Paper, { PaperProps } from '@mui/material/Paper'

// ----------------------------------------------------------------------

interface Props extends PaperProps {
  query?: string
  showNoResults?: boolean
}

export default function SearchNotFound({ query, showNoResults, sx, ...other }: Props) {
  return query || showNoResults ? (
    <Paper
      sx={{
        bgcolor: 'unset',
        textAlign: 'center',
        ...sx,
      }}
      {...other}
    >
      <Typography variant="h6" gutterBottom>
        Not Found
      </Typography>

      <Typography variant="body2">
        No results found
        {!showNoResults && (
          <>
            for <strong>&quot;{query}&quot;</strong>.
          </>
        )}
        <br /> Try checking for typos or using complete words.
      </Typography>
    </Paper>
  ) : (
    <Typography variant="body2" sx={sx}>
      Please enter keywords
    </Typography>
  )
}
