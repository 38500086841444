// @mui
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
// assets
import { useCallback } from 'react'
import { SeverErrorIllustration } from 'src/assets/illustrations'
// components
import { paths } from 'src/routes/paths'
import { useAuthContext } from 'src/auth/hooks'
import { useErrorContext } from 'src/components/error-context/error-context'

// ----------------------------------------------------------------------

export default function UnexpectedErrorView() {
  const { logError } = useErrorContext()
  const { logout } = useAuthContext()

  const handleLogout = useCallback(async () => {
    try {
      await logout()
      // wrap in setTimeout because the window object may not be available in an async function
      setTimeout(() => {
        // use the window object because the router is dead
        window.location.href = paths.auth.jwt.login()
      })
    } catch (error) {
      logError(error)
    }
  }, [logError, logout])

  return (
    <Box>
      <Box>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Oops! We ran into an unexpected issue.
        </Typography>
      </Box>

      <Box>
        <Typography sx={{ color: 'text.secondary' }}>
          We encountered an unexpected error, please try logging back in.
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>If the problem persists, please contact support.</Typography>
      </Box>

      <Box>
        <SeverErrorIllustration sx={{ height: 180, my: 5 }} />
      </Box>

      <Button onClick={handleLogout} size="large" variant="contained" sx={{ px: 4 }}>
        Log in
      </Button>
    </Box>
  )
}
