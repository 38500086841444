import * as Sentry from '@sentry/react'
import { useMemo } from 'react'
import { DEPLOY_ENV, SENTRY_DSN } from 'src/config-global'
import LastResortErrorBoundaryFlalbackPage from 'src/pages/last-resort-error-boundary-fallback-page'
import { ErrorContext } from './error-context'

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: DEPLOY_ENV,
    // in production, sample 1% of transactions
    tracesSampleRate: 0.01,
    normalizeDepth: 20,
  })
}

export default function ErrorContextProvider({ children }: { children: React.ReactNode }) {
  const errorContextValue = useMemo(
    () => ({
      logError: (error: Error, additionalData?: object, tags?: { [key: string]: any }) => {
        Sentry.captureException(error, {
          tags,
          extra: {
            ...additionalData,
          },
        })
        console.error(error)
      },
      logMessage: (message: string, additionalData?: object, tags?: { [key: string]: any }) => {
        Sentry.captureMessage(message, {
          tags,
          extra: {
            ...additionalData,
          },
        })
        console.log(message)
      },
      setUser: (userId: string, email?: string | undefined | null) => {
        let segment = 'unknown'
        if (email) {
          segment = email.trim().match(/@(ambient.us|twine.us|twine.nyc)/i) ? 'employee' : 'customer'
        }
        Sentry.setTag('user_segment', segment)
        Sentry.setUser({
          id: userId,
          segment,
        })
      },
      logout: async () => {
        await Sentry.flush()
        Sentry.setUser(null)
        Sentry.setTag('user_segment', 'unknown')
      },
    }),
    []
  )

  return (
    <Sentry.ErrorBoundary fallback={<LastResortErrorBoundaryFlalbackPage />}>
      <ErrorContext.Provider value={errorContextValue}>{children}</ErrorContext.Provider>
    </Sentry.ErrorBoundary>
  )
}
