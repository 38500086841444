// ----------------------------------------------------------------------

import { useCallback, useMemo, useState } from 'react'
import { IntercomProvider, useIntercom } from 'react-use-intercom'
import { INTERCOM_APP_ID } from 'src/config-global'
import { AmbientIntercomProviderContext } from './ambient-intercom-context'
import { IntercomCustomUserAttributes } from './types'

export default function AmbientIntercomProvider({ children }: { children: React.ReactNode }) {
  const [unreadMessages, setUnreadMessages] = useState(0)

  // unfortunately, Intercom doesn't provide a way to get the unread count so we have to listen to the event
  const handleOnUnreadCountChange = useCallback((unreadCount: number) => {
    setUnreadMessages(unreadCount)
  }, [])

  // don't initialize for 2 seconds to avoid unnecessary requests during page load
  const initializeDelay = 2000
  return (
    <IntercomProvider
      appId={INTERCOM_APP_ID}
      autoBoot
      onUnreadCountChange={handleOnUnreadCountChange}
      initializeDelay={initializeDelay}
      autoBootProps={{
        backgroundColor: '#2299AA',
        actionColor: '#1C1C2C',
      }}
    >
      <IntercomStateProvider unreadMessages={unreadMessages}>{children}</IntercomStateProvider>
    </IntercomProvider>
  )
}

function IntercomStateProvider({ children, unreadMessages }: { children: React.ReactNode; unreadMessages: number }) {
  const { trackEvent, update } = useIntercom()

  const providerState = useMemo(
    () => ({
      unreadMessages,
      trackIntercomEvent: (eventName: string, metaData?: object) => {
        trackEvent(eventName, metaData)
      },
      updateIntercomCutomUserProps: (props: Partial<IntercomCustomUserAttributes>) => {
        update({ customAttributes: props })
      },
    }),
    [unreadMessages, trackEvent, update]
  )
  return (
    <AmbientIntercomProviderContext.Provider value={providerState}>{children}</AmbientIntercomProviderContext.Provider>
  )
}
