import { makeVar } from '@apollo/client'

export enum ConnectionStatus {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  RECONNECTING = 'RECONNECTING',
  RECONNECTED = 'RECONNECTED',
}

export const subscriptionConnectionStatus = makeVar<ConnectionStatus>(ConnectionStatus.DISCONNECTED)
export const websocketLastSeenSeverTime = makeVar<Date>(new Date())
