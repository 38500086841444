import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import { useCallback } from 'react'
import { useBoolean } from 'src/hooks/use-boolean'

export default function ContactUsCalendlyDialog({ open, onClose }: { open: boolean; onClose: VoidFunction }) {
  const loading = useBoolean(true)
  const handleClose = useCallback(() => {
    onClose()
    loading.onTrue()
  }, [loading, onClose])

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth sx={{ pointerEvents: 'all' }}>
      <Stack sx={{ height: '1000px' }} alignItems="center" justifyContent="center">
        {loading.value && <CircularProgress size={100} />}
        <iframe
          src="https://calendly.com/d/3jv-m27-jhw/ambient-onboarding"
          width="100%"
          height="100%"
          title="calendly-contact-us"
          onLoad={loading.onFalse}
          hidden={loading.value}
        />
      </Stack>
    </Dialog>
  )
}
