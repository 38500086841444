import { useForm } from 'react-hook-form'
import { useCallback, useState } from 'react'
// @mui
import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
// routes
import { useParams } from 'react-router'
// config
// hooks
// auth
import { Alert, Link } from '@mui/material'
import { useAuthContext } from 'src/auth/hooks'
import { useRouter, useSearchParams } from 'src/routes/hooks'
// components
import FormProvider from 'src/components/hook-form'
import { PATH_AFTER_LOGIN } from 'src/config-global'
import { useErrorContext } from 'src/components/error-context/error-context'
import { useAnalytics } from 'src/components/analytics'

// ----------------------------------------------------------------------

export default function JwtPreauthorizedView() {
  const { track } = useAnalytics()
  const { logError } = useErrorContext()
  const { exchangeCode } = useAuthContext()
  const { code } = useParams()
  const router = useRouter()

  const [errorMsg, setErrorMsg] = useState('')

  const searchParams = useSearchParams()
  const nonce = searchParams.get('nonce')
  const sub = searchParams.get('sub')
  const returnTo =
    searchParams.get('returnTo') ??
    searchParams.get('redirect_url') ??
    searchParams.get('redirect_path') ??
    searchParams.get('return') ??
    PATH_AFTER_LOGIN

  const methods = useForm()

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = handleSubmit(
    useCallback(
      async (data) => {
        track('Click', { element: 'login_via_preauthorized_code' })
        try {
          await exchangeCode(code!, nonce!, sub!)
          router.push(returnTo)
        } catch (error) {
          logError(error)
          reset()
          setErrorMsg(typeof error === 'string' ? error : error.message)
        }
      },
      [code, exchangeCode, logError, nonce, reset, returnTo, router, sub, track]
    )
  )

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5 }}>
      <Typography variant="h4">Continue to Ambient</Typography>

      <Typography variant="body2">Click the button to login</Typography>
    </Stack>
  )

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
      <LoadingButton fullWidth color="inherit" size="large" type="submit" variant="contained" loading={isSubmitting}>
        Continue
      </LoadingButton>
    </Stack>
  )

  const renderPrivacyPolicy = (
    <Stack spacing={2} sx={{ mt: 3 }} textAlign="center">
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {`By clicking "Continue" you acknowledge that you have read and agreed to the Ambient `}
        <Link href="https://www.ambient.us/privacy-policy" variant="subtitle2">
          Privacy Policy
        </Link>{' '}
        {`and `}
        <Link href="https://www.ambient.us/terms-of-service" variant="subtitle2">
          Terms of Service
        </Link>
        .
      </Typography>
    </Stack>
  )

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}
      {renderForm}
      {renderPrivacyPolicy}
    </FormProvider>
  )
}
