// @mui
import Box, { BoxProps } from '@mui/material/Box'
// hooks
import { useResponsive } from 'src/hooks/use-responsive'
// components
import { useSettingsContext } from 'src/components/settings'
//
import { useSubscriptionStatus } from 'src/hooks/use-subscription-status'
import { HEADER, NAV } from '../config-layout'

// ----------------------------------------------------------------------

const SPACING = 8

export default function Main({ children, sx, ...other }: BoxProps) {
  const settings = useSettingsContext()

  const lgUp = useResponsive('up', NAV.SHOW_VERT_NAVBAR_SIZE)

  const isNavHorizontal = settings.themeLayout === 'horizontal'

  const isNavMini = settings.themeLayout === 'mini'

  const { isTrial, subscribed } = useSubscriptionStatus()
  const trialHeight = isTrial || !subscribed ? HEADER.H_TRIAL : 0

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          minHeight: 0,
          display: 'flex',
          flexDirection: 'column',
          pt: `${HEADER.H_MOBILE + trialHeight + 24}px`,
          pb: 10,
          px: 0,
          ...(lgUp && {
            pt: `${HEADER.H_MOBILE * 2 + 40}px`,
            pb: 15,
          }),
        }}
      >
        {children}
      </Box>
    )
  }

  return (
    <Box
      component="main"
      sx={{
        // backgroundColor: 'background.paper',
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        pb: 2,
        pt: `${HEADER.H_DESKTOP + trialHeight + SPACING}px`,
        px: 0,
        mx: 0,
        ...(lgUp && {
          mx: 0,
          pt: `${HEADER.H_DESKTOP + trialHeight + SPACING}px`,
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI}px)`,
          }),
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  )
}
