import { useEffect } from 'react'
import { useAnalytics } from './components/analytics'
import './desktop-app.css'
import { useDesktopApp } from './hooks/use-desktop-app'

/**
 * Simple wrapper that adds a draggable header to the top of the app.
 *
 * This won't be rendered in the browser
 * @returns
 */
export function DesktopAppWrapper({ children }: { children: React.ReactNode }) {
  const { registerMetaData } = useAnalytics()
  const { isDesktopApp } = useDesktopApp()

  useEffect(() => {
    registerMetaData({ desktop_app: isDesktopApp })
  }, [registerMetaData, isDesktopApp])

  return (
    <>
      <div className="header" />
      {children}
    </>
  )
}
