import { useMemo } from 'react'
import mixpanel from 'mixpanel-browser'
import { MIXPANEL_TOKEN, MIXPANEL_DEBUG } from 'src/config-global'
import { User } from 'src/__generated__/graphql'
import { AnalyticsContext } from './analytics-context'
import { AnalyticsEvent, AnalyticsUserProfileData, NarrowByEventName } from './types'

if (MIXPANEL_TOKEN) {
  mixpanel.init(MIXPANEL_TOKEN, {
    debug: MIXPANEL_DEBUG,
  })
}

export default function AnalyticsProvider({ children }: { children: React.ReactNode }) {
  const errorContextValue = useMemo(
    () => ({
      track: <EventName extends AnalyticsEvent['name']>(
        eventName: EventName,
        data: NarrowByEventName<AnalyticsEvent, EventName>
      ) => {
        mixpanel.track(eventName, data)
      },
      trackPageView: () => {
        mixpanel.track_pageview()
      },
      identifyUser: (user: User) => {
        mixpanel.identify(user.id)
        // set data on the user profile
        mixpanel.people.set({
          email: user.email,
          name: user.name,
          created_at: user.userProfile?.createdAt,
          org_id: user.orgId,
          personal_integrations: user.userIntegrations?.map((i) => i.type),
          preferences: user.personalizedAppSettings?.settings,
        })
      },
      updateAnalyticsProfile: (data?: Partial<AnalyticsUserProfileData>) => {
        // set data on the user profile
        mixpanel.people.set({ ...data })
      },
      onLogout: async () => {
        mixpanel.reset()
      },
      /**
       * Register metadata that will be sent with every event
       * @param data a dictionary of data to be sent with every event
       */
      registerMetaData: (data: Record<string, any>) => {
        mixpanel.register({ ...data })
      },
    }),
    []
  )

  return <AnalyticsContext.Provider value={errorContextValue}>{children}</AnalyticsContext.Provider>
}
