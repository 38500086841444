import { Helmet } from 'react-helmet-async'
// sections
import { UnexpectedErrorView } from 'src/sections/error'

// ----------------------------------------------------------------------

export default function RouterErrorBoundaryPage() {
  return (
    <>
      <Helmet>
        <title> Oops! We ran into an issue...</title>
      </Helmet>

      <UnexpectedErrorView />
    </>
  )
}
