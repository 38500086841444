import merge from 'lodash/merge'
import { useMemo } from 'react'
// @mui
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider as MuiThemeProvider, ThemeOptions } from '@mui/material/styles'
// locales
// import useMediaQuery from '@mui/material/useMediaQuery'
import { Helmet } from 'react-helmet-async'
import { useLocales } from 'src/locales'
// components
import { useSettingsContext } from 'src/components/settings'
// system
import { useSystemThemeMode } from 'src/hooks/use-system-theme-mode'
import { palette } from './palette'
import { shadows } from './shadows'
import { typography } from './typography'
import { customShadows } from './custom-shadows'
import { componentsOverrides } from './overrides'
// options
import { presets } from './options/presets'
import { darkMode } from './options/dark-mode'
import { contrast } from './options/contrast'

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
}

export default function ThemeProvider({ children }: Props) {
  const { currentLang } = useLocales()

  const settings = useSettingsContext()
  const { currentThemeMode } = useSystemThemeMode()

  // ! TODO - ENABLE THIS
  const darkModeOption = darkMode(currentThemeMode(settings.themeMode))

  const presetsOption = presets(settings.themeColorPresets)

  const contrastOption = contrast(settings.themeContrast === 'bold', currentThemeMode(settings.themeMode))

  const baseOption = useMemo(
    () => ({
      palette: palette('light'),
      shadows: shadows('light'),
      customShadows: customShadows('light'),
      typography,
      shape: { borderRadius: 8 },
    }),
    []
  )

  const memoizedValue = useMemo(
    () =>
      merge(
        // Base
        baseOption,
        // Dark mode: remove if not in use
        darkModeOption,
        // Presets: remove if not in use
        presetsOption,
        // Contrast: remove if not in use
        contrastOption.theme
      ),
    [baseOption, darkModeOption, presetsOption, contrastOption.theme]
  )

  const theme = createTheme(memoizedValue as ThemeOptions)

  theme.components = merge(componentsOverrides(theme), contrastOption.components)

  const themeWithLocale = useMemo(() => createTheme(theme, currentLang.systemValue), [currentLang.systemValue, theme])

  return (
    <MuiThemeProvider theme={themeWithLocale}>
      <CssBaseline />
      {/* Make the browser chrome match the light/dark theme */}
      <Helmet>
        <meta name="theme-color" content={themeWithLocale.palette.background.default} />
      </Helmet>
      {children}
    </MuiThemeProvider>
  )
}
