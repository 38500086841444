export enum RecordingStatus {
  unknown = 'unknown',
  // the user has requested to start recording, awaiting the bot joining the meeting
  meetignNotStarted = 'not_started',
  recordingRequested = 'recording_requested',
  recordingBotJoining = 'recording_bot_joining',
  recordingBotInWaitingRoom = 'recording_bot_in_waiting_room',
  recordingBotInCallNotRecording = 'recording_bot_in_call_not_recording',
  // the user has requested to start recording, but the request to Recall failed
  botRequestFailed = 'bot_request_failed',
  // the bot has successfully joined the meeting and has started recording
  recordingStarted = 'recording_started',
  // the user has requested to stop recording, awaiting the bot leaving the meeting
  recordingStopRequested = 'recording_stop_requested',
  // the user has requested to stop recording, but the request to Recall failed
  botLeaveRequestFailed = 'bot_leave_request_failed',
  // the bot has successfully left the meeting and has stopped recording
  recordingCompleted = 'recording_completed',
  // the bot request returned 503 because the bot pool is currently exhausted
  botPoolExhausted = 'botPoolExhausted',
}
