// @mui
import { useTheme } from '@mui/material/styles'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
// theme
import { bgBlur } from 'src/theme/css'
// routes
import { paths } from 'src/routes/paths'
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top'
// components
import Logo, { LogoFull } from 'src/components/logo'
import { RouterLink } from 'src/routes/components'
//
import { HEADER } from '../config-layout'
import HeaderShadow from './header-shadow'

// ----------------------------------------------------------------------

interface Props {
  fullLogo?: boolean
}

export default function HeaderSimple({ fullLogo = false }: Props) {
  const theme = useTheme()
  const offsetTop = useOffSetTop(HEADER.H_DESKTOP)

  return (
    <AppBar>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        {fullLogo ? <LogoFull sx={{ width: 110 }} /> : <Logo sx={{ width: 24 }} />}

        <Stack direction="row" alignItems="center" spacing={1}>
          <Link
            intercom-data-attribute="help"
            href={paths.help}
            component={RouterLink}
            color="inherit"
            sx={{ typography: 'subtitle2' }}
          >
            Need help?
          </Link>
        </Stack>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  )
}
