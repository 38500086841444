// utils
import { PostAuthorFilter } from 'src/types/feed'

// ----------------------------------------------------------------------

const ROOTS = {
  SHARE: '/share',
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
}

// ----------------------------------------------------------------------

export const paths = {
  help: 'https://help.ambient.us',
  maintenance: '/maintenance',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  share: (slug: string) => `${ROOTS.SHARE}/${slug}`,
  // AUTH
  auth: {
    jwt: {
      login: (returnTo?: string, source?: string, sourceRef?: string, trialCode?: string) => {
        const searchParams = new URLSearchParams()
        if (returnTo) searchParams.append('return', returnTo)
        if (source) searchParams.append('source', source)
        if (source && sourceRef) searchParams.append('source_ref', sourceRef)
        if (trialCode) searchParams.append('trial_code', trialCode)
        return `${ROOTS.AUTH}/jwt/login?${searchParams.toString()}`
      },
      logout: `${ROOTS.AUTH}/jwt/logout`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    home: `${ROOTS.DASHBOARD}/home`,
    general: {},
    user: {
      account: `${ROOTS.DASHBOARD}/user/account`,
      calendarSettings: `${ROOTS.DASHBOARD}/user/account#calendar`,
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      rootWithFilter: (author: PostAuthorFilter) => `${ROOTS.DASHBOARD}/post?a=${author}`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (postId: string, isDraft: boolean = false, isSlug: boolean = false) =>
        `${ROOTS.DASHBOARD}/post/${postId}${isDraft ? '/draft' : ''}${isSlug ? '?shared=true' : ''}`,
      edit: (postId: string, isDraft: boolean = false) =>
        `${ROOTS.DASHBOARD}/post/${postId}/edit${isDraft ? '/draft' : ''}`,
    },
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    meetings: {
      list: `${ROOTS.DASHBOARD}/meetings`,
      // these parameters match the post parameters until we can redesign the meeting details page to show multiple posts
      details: (postId: string, isDraft: boolean = false) => {
        const searchParams = new URLSearchParams()
        if (isDraft) searchParams.append('draft', 'true')

        return `${ROOTS.DASHBOARD}/meetings/${postId}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`
      },
    },
    projects: {
      root: `${ROOTS.DASHBOARD}/projects`,
      details: (id: string) => `${ROOTS.DASHBOARD}/projects/${id}`,
    },
    meetingSeries: {
      listLegacy: `${ROOTS.DASHBOARD}/meetingseries`,
      listWithFilter: (type?: '1to1' | 'team' | 'external') => {
        if (type) return `${ROOTS.DASHBOARD}/meetingseries/list?type=${type}`
        return `${ROOTS.DASHBOARD}/meetingseries/list?all`
      },
      details: (meetingId: string) => `${ROOTS.DASHBOARD}/meetingseries/${meetingId}`,
      meetingDetails: (meetingSeriesId: string, postId: string, isDraft: boolean = false) => {
        const searchParams = new URLSearchParams()
        if (isDraft) searchParams.append('draft', 'true')

        return `${ROOTS.DASHBOARD}/meetingseries/${meetingSeriesId}/meetings/${postId}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`
      },
    },
  },
}
