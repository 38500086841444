// @mui
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
//
import { HeaderSimple as Header } from '../_common'

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
}

export default function PublicShareLayout({ children }: Props) {
  return (
    <>
      <Header fullLogo />

      <Container component="main">
        <Stack
          sx={{
            py: 12,
            m: 'auto',
            // minHeight: '100vh',
          }}
        >
          {children}
        </Stack>
      </Container>
    </>
  )
}
