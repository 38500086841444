import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
// layouts
import CompactLayout from 'src/layouts/compact'
// components
import { SplashScreen } from 'src/components/loading-screen'

// ----------------------------------------------------------------------

const SlackConnectedPage = lazy(() => import('src/pages/integration/slack-connected-page'))
const CalendarConnectedPage = lazy(() => import('src/pages/integration/calendar-connected-page'))

// ----------------------------------------------------------------------

/**
 * These routes handle termination points for integration installation.
 * It's usually at the end of the OAuth flow.
 */
export const integrationRoutes = [
  {
    path: 'integrations',
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: 'slack/connected', element: <SlackConnectedPage /> },
      { path: 'calendar/connected', element: <CalendarConnectedPage /> },
    ],
  },
]
