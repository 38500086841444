import { Handler } from 'mitt'
import { useEffect } from 'react'
import { useEventBusContext } from 'src/components/event-bus/event-bus-context'
import { EventBusEvents } from 'src/components/event-bus/events'

/**
 * This hook allows you to subscribe to an event on the global event bus.
 * Events will automatically be unsubscribed when the component is unmounted.
 */
export function useSubscribe<K extends keyof EventBusEvents>(event: K, callback: Handler<EventBusEvents[K]>) {
  const { emitter } = useEventBusContext<EventBusEvents>()

  useEffect(() => {
    emitter.on(event, callback)

    return () => {
      emitter.off(event, callback)
    }
  }, [callback, emitter, event])
}

export function useSubscribeLocal<T extends Record<string, any>>(event: keyof T, callback: Handler<T[typeof event]>) {
  const { emitter } = useEventBusContext<T>()

  useEffect(() => {
    emitter.on(event, callback)

    return () => {
      emitter.off(event, callback)
    }
  }, [callback, emitter, event])
}

/**
 * This hook allows you to send to an event on the global event bus.
 */
export function useEmit<K extends keyof EventBusEvents>(event: K) {
  const { emitter } = useEventBusContext<EventBusEvents>()

  const emit = (data: EventBusEvents[K]) => {
    emitter.emit(event, data)
  }

  return emit
}

export function useEmitLocal<T extends Record<string, any>>(event: keyof T) {
  const { emitter } = useEventBusContext<T>()

  const emit = (data: T[typeof event]) => {
    emitter.emit(event, data)
  }

  return emit
}
