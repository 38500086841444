import { createContext, useContext } from 'react'

// ----------------------------------------------------------------------

export type ErrorContextState = {
  logError: (error: Error, additionalData?: object) => void
  logMessage: (message: string, additionalData?: object) => void
  setUser: (userId: string, email?: string | undefined | null) => void
  logout: () => void
}

export const ErrorContext = createContext<ErrorContextState>({} as ErrorContextState)

/**
 * A hook to access the ErrorContextState
 * @returns an object with methods to log errors and messages to Sentry
 */
export const useErrorContext = () => {
  const context = useContext(ErrorContext)

  if (!context) throw new Error('useErrorContext must be use inside ErrorContextProvider')

  return context
}
