/**
 * Validate that the input is a valid Zoom meeting link
 * @param link
 * @returns
 */
function validateZoomMeetingLink(link: string) {
  // Define the regex pattern to match Zoom meeting links
  // e.g. https://us02web.zoom.us/j/9855694117 or us02web.zoom.us/j/9855694117
  //      https://apollo.zoom.us/my/great/custom/link or apollo.zoom.us/my/great/custom/link (SC-17574)
  const pattern =
    /^(?:https?:\/\/)?(?:www\.)?(?:[\w-]+\.)?zoom\.us\/((?:my\/[^\s/?#]+\/?)|(?:\w+\/)?\w+)(?:\/)?(?:\?pwd=\w+)?([\\?&]([^&=]+)=([^&=]+))*$/
  const calltowerPattern = /^(?:https?:\/\/)?(?:www\.)?calltower\.\w+\.com\/j\/\w+/
  // calltower is a platform build on top of zoom, so we need to check for that first
  const calltower = calltowerPattern.test(link)
  if (calltower) return true
  // Check if the input matches the pattern
  return pattern.test(link)
}

/**
 * Validate that the input is a valid Google Meet meeting link
 * @param link
 * @returns
 */
function validateGoogleMeetMeetingLink(link: string) {
  // Define the regex pattern to match Google Meet links
  // e.g. https://meet.google.com/jcv-ipqm-bav
  // OR meet.google.com/jcv-ipqm-bav because this is the way Google renders it in the meeting...
  const pattern = /^(?:https?:\/\/)?meet\.google\.com\/[a-zA-Z0-9-_]+([\\?&]([^&=]+)=([^&=]+))*$/

  // Check if the input matches the pattern
  return pattern.test(link)
}

/**
 * Validate that the input is a valid Microsoft Teams meeting link
 * @param link
 * @returns
 */
function validateTeamsMeetingLink(link: string) {
  // Define the regex pattern to match Google Meet links
  // e.g. https://teams.live.com/meet/9373474523918?p=pNKjLbMdHBqwspHl
  // e.g. https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZWViNTAwY2YtNDk2ZC00NDU5LTk0OGEtZWI4ZDgyODEzOTdk%40thread.v2/0?context=%7b%22Tid%22%3a%22472d1d61-3d2e-415f-9dff-a6d01c634825%22%2c%22Oid%22%3a%225710ac2e-e6b5-44c3-a37c-c8b6e21972fb%22%7d
  const pattern =
    /^((?:https?:\/\/)?teams\.live\.com\/meet\/[0-9]+(?:\?p=[0-9A-z]+)?([\\?&]([^&=]+)=([^&=]+))*)|((?:https?:\/\/)?teams\.microsoft\.com\/l\/meetup-join.*)$/

  // Check if the input matches the pattern
  return pattern.test(link)
}

/**
 * Determines the type of meeting link provided or undefined if it is not a valid meeting link
 * @param link the meeting link to validate
 * @returns the type of meeting link or undefined if it is not a valid meeting link
 */
export function getValidMeetingLinkType(link: string): 'zoom' | 'meet' | 'teams' | undefined {
  if (validateZoomMeetingLink(link)) {
    return 'zoom'
  }

  if (validateGoogleMeetMeetingLink(link)) {
    return 'meet'
  }

  if (validateTeamsMeetingLink(link)) {
    return 'teams'
  }

  return undefined
}
