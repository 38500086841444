import { MeetingRecording } from 'src/__generated__/graphql'
import { RecordingStatus } from 'src/types/meeting'

/**
 * Return the active recording from a list of recordings
 * @param recordings a list of recordings from the API
 * @param currentRecording the current recording ID
 * @returns the active recording or undefined if there is no active recording
 */
export function findActiveRecording(recordings: MeetingRecording[] = [], currentRecording?: string) {
  const activeStatus = [
    RecordingStatus.recordingRequested,
    RecordingStatus.recordingBotJoining,
    RecordingStatus.recordingBotInWaitingRoom,
    RecordingStatus.recordingBotInCallNotRecording,
    RecordingStatus.recordingStarted,
    RecordingStatus.recordingStopRequested,
  ].map((s) => s.toString())

  const active = recordings.find(
    (r) =>
      activeStatus.includes(r.recordingStatus) || (r.summaryStatus === 'summary_error' && r.id === currentRecording)
  )
  return active
}

/**
 * Return the active recordings from a list of recordings
 * @param recordings a list of recordings from the API
 * @param currentRecording the current recording ID
 * @returns the active recording or undefined if there is no active recording
 */
export function findActiveRecordings(recordings: MeetingRecording[] = [], currentRecording?: string) {
  const activeStatus = [
    RecordingStatus.recordingRequested,
    RecordingStatus.recordingBotJoining,
    RecordingStatus.recordingBotInWaitingRoom,
    RecordingStatus.recordingBotInCallNotRecording,
    RecordingStatus.recordingStarted,
    RecordingStatus.recordingStopRequested,
  ].map((s) => s.toString())

  const active = recordings.filter(
    (r) =>
      activeStatus.includes(r.recordingStatus) || (r.summaryStatus === 'summary_error' && r.id === currentRecording)
  )
  return active
}

/**
 * Return a list of active recordings that were scheduled from a users calendar from a list of recordings from the API
 * @param recordings a list of recordings from the API
 * @returns the active recording or undefined if there is no active recording
 */
export function findActiveScheduledRecordings(recordings: MeetingRecording[] = []) {
  const activeStatus = [
    RecordingStatus.recordingRequested,
    RecordingStatus.recordingBotJoining,
    RecordingStatus.recordingBotInWaitingRoom,
    RecordingStatus.recordingBotInCallNotRecording,
    RecordingStatus.recordingStarted,
    RecordingStatus.recordingStopRequested,
  ].map((s) => s.toString())

  const active = recordings.filter((r) => activeStatus.includes(r.recordingStatus) && r.scheduled)
  return active
}
