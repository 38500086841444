import { m } from 'framer-motion'
// @mui
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
// routes
import { useCallback } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { paths } from 'src/routes/paths'
import { useRouter } from 'src/routes/hooks'
// hooks
// auth
import { useAuthContext } from 'src/auth/hooks'
// components
import { varHover } from 'src/components/animate'
import { useSnackbar } from 'src/components/snackbar'
import CustomPopover, { usePopover } from 'src/components/custom-popover'
import { useSettingsContext } from 'src/components/settings'
import Iconify from 'src/components/iconify/iconify'
import { useErrorContext } from 'src/components/error-context/error-context'
import { useAnalytics } from 'src/components/analytics'
import { useQuery } from '@apollo/client'
import { getDelegateInfo } from 'src/graphql/queries'
import { Chip, Tooltip } from '@mui/material'

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { track } = useAnalytics()
  const router = useRouter()
  const { logError } = useErrorContext()

  const { logout, user, switchAccounts } = useAuthContext()

  const { enqueueSnackbar } = useSnackbar()

  const popover = usePopover()

  const handleLogout = useCallback(async () => {
    track('Click', { element: 'account_popover', item: 'logout' })
    try {
      await logout()
      popover.onClose()
      router.replace('/')
    } catch (error) {
      logError(error)
      enqueueSnackbar('Unable to logout!', { variant: 'error' })
    }
  }, [enqueueSnackbar, logError, logout, popover, router, track])

  const handleClickHome = useCallback(() => {
    track('Click', { element: 'account_popover', item: 'home' })
    popover.onClose()
    router.push(paths.dashboard.home)
  }, [popover, router, track])

  const handleClickSettings = useCallback(() => {
    track('Click', { element: 'account_popover', item: 'settings' })
    popover.onClose()
    router.push(paths.dashboard.user.calendarSettings)
  }, [popover, router, track])

  const handleSwitchAccountToImpersonator = useCallback(() => {
    if (!user?.impersonator) return
    switchAccounts(user?.impersonator.id, user?.impersonator.orgId, false)
    popover.onClose()
  }, [popover, switchAccounts, user?.impersonator])

  return (
    <>
      <Stack direction="row" spacing={1}>
        {user?.impersonator && (
          <Tooltip title="This is a delegate session" placement="top">
            <Chip
              size="small"
              variant="filled"
              color="warning"
              icon={<Iconify icon="tabler:user-circle" width={16} />}
              label={user.email}
              sx={{ my: 'auto' }}
            />
          </Tooltip>
        )}
        <IconButton
          className="override-todesktop-drag"
          intercom-data-attribute="account-popover"
          component={m.button}
          whileTap="tap"
          whileHover="hover"
          variants={varHover(1.05)}
          onClick={popover.onOpen}
          sx={{
            width: 40,
            height: 40,
            background: (theme) => alpha(theme.palette.grey[500], 0.08),
            ...(popover.open && {
              background: (theme) =>
                `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
            }),
          }}
        >
          <Avatar
            src={user?.userProfile?.picUrl ?? ''}
            alt={user?.name ?? ''}
            sx={{
              width: 36,
              height: 36,
              border: (theme) =>
                `solid 2px ${user?.impersonator ? theme.palette.warning.main : theme.palette.background.default}`,
            }}
          />
        </IconButton>
      </Stack>

      <CustomPopover
        intercom-data-attribute="account-popover"
        open={popover.open}
        onClose={popover.onClose}
        slotProps={{
          paper: {
            sx: {
              width: 300,
              mt: 1,
              mx: 1,
            },
          },
        }}
      >
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>

          <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
            {user?.impersonator && (
              <Chip size="small" variant="soft" color="warning" label="Delegated" sx={{ my: 'auto', ml: 1 }} />
            )}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem intercom-data-attribute="home" key="home" onClick={handleClickHome}>
            Home
          </MenuItem>
          <MenuItem intercom-data-attribute="settings" key="settings" onClick={handleClickSettings}>
            Account
          </MenuItem>
        </Stack>
        <DelegateAccessList />

        {user?.impersonator && (
          <MenuItem
            intercom-data-attribute="logout"
            onClick={handleSwitchAccountToImpersonator}
            sx={{ m: 1, mt: -1, fontWeight: 'fontWeightBold', color: 'primary.main' }}
          >
            <Iconify icon="tabler:arrow-back-up" width={16} sx={{ mr: 1 }} />
            Return to {user?.impersonator.email}
          </MenuItem>
        )}

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack sx={{ px: 2, py: 1 }} spacing={1} alignContent="baseline">
          <Typography variant="body2" sx={{ flexGrow: 1 }}>
            Theme
          </Typography>
          <DarkModeSwitch />
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem
          intercom-data-attribute="logout"
          onClick={handleLogout}
          sx={{
            m: 1,
            fontWeight: user?.impersonator ? undefined : 'fontWeightBold',
            color: user?.impersonator ? 'text.secondary' : 'primary.main',
          }}
        >
          <Iconify icon="tabler:logout-2" width={16} sx={{ mr: 1 }} />
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  )
}

function DelegateAccessList() {
  const { user } = useAuthContext()
  const { data } = useQuery(getDelegateInfo, {
    fetchPolicy: 'cache-and-network',
  })
  const { switchAccounts } = useAuthContext()

  if (!data?.getDelegateInfo.delegationEnabled) return null
  const { getDelegateInfo: info } = data || {}
  if (!info || info.delegateHosts.length < 1) return null
  return (
    <>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Stack sx={{ p: 1 }}>
        <Tooltip title="You have access to these accounts. Click to switch to the users." placement="top">
          <Typography variant="body2" sx={{ flexGrow: 1, px: 1 }}>
            Delegate Access
          </Typography>
        </Tooltip>
        {info.delegateHosts.map((host, i) => (
          <MenuItem
            disabled={host.id === user?.id}
            onClick={() => switchAccounts(host.id, host.orgId!, true)}
            key={i}
            sx={{ color: 'text.primary' }}
          >
            <Iconify
              icon={host.id === user?.id ? 'tabler:circle-check-filled' : 'tabler:user-circle'}
              width={16}
              sx={{ mr: 1 }}
            />
            {host.email}
          </MenuItem>
        ))}
      </Stack>
    </>
  )
}

function DarkModeSwitch() {
  const { track } = useAnalytics()
  const settings = useSettingsContext()

  const handleChange = useCallback(
    (_event: any, value: 'light' | 'dark' | 'system') => {
      if (!value) return
      track('Click', { element: 'theme_mode', mode: value })
      settings.onUpdate('themeMode', value)
    },
    [settings, track]
  )

  return (
    <ToggleButtonGroup
      sx={{ height: 30, m: 0 }}
      fullWidth
      size="small"
      exclusive
      onChange={handleChange}
      value={settings.themeMode}
    >
      <ToggleButton value="light" size="small">
        <Iconify icon="tabler:sun" width={16} />
      </ToggleButton>
      <ToggleButton value="system" size="small" sx={{ fontWeight: 'normal' }}>
        System
      </ToggleButton>
      <ToggleButton value="dark" size="small">
        <Iconify icon="tabler:moon-filled" width={16} />
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
