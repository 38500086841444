import { useCallback, useEffect } from 'react'
// routes
import { paths } from 'src/routes/paths'
import { useParams, useRouter, useSearchParams } from 'src/routes/hooks'
//
import { useAuthContext } from '../hooks'

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
  forwardTo?: string | ((params: ReturnType<typeof useParams>) => string)
}

export default function GuestGuard({ children, forwardTo }: Props) {
  const router = useRouter()
  const params = useParams()

  const searchParams = useSearchParams()

  let returnTo: string
  if (typeof forwardTo === 'function') {
    returnTo = forwardTo(params)
  } else {
    // redirect_url is used by our shared post workflow
    // returnTo is used by the frontend internally
    returnTo =
      forwardTo ??
      searchParams.get('returnTo') ??
      searchParams.get('redirect_url') ??
      searchParams.get('return') ??
      searchParams.get('redirect_path') ??
      paths.dashboard.root
  }

  const { authenticated } = useAuthContext()

  const check = useCallback(() => {
    if (authenticated) {
      router.replace(returnTo)
    }
  }, [authenticated, returnTo, router])

  useEffect(() => {
    check()
  }, [check])

  return <>{children}</>
}
