import { useEffect, useCallback, useState } from 'react'
// routes
import { useNavigate, useSearchParams } from 'react-router-dom'
import { paths } from 'src/routes/paths'
import { useRouter } from 'src/routes/hooks'
//
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  LinearProgress,
} from '@mui/material'
import { useInterval } from 'usehooks-ts'
import { useSnackbar } from 'notistack'
import { useAuthContext } from '../hooks'
import { refreshLoginSession } from '../utils'

const MAX_RETRY_ATTEMPTS = 3
const MAX_RETRY_DELAY_MS = 20000
// ----------------------------------------------------------------------

const loginPaths: Record<string, string> = {
  jwt: paths.auth.jwt.login(),
}

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
}

export default function AuthGuard({ children }: Props) {
  const router = useRouter()
  const { authenticated, method } = useAuthContext()
  const [searchParams] = useSearchParams()

  const [checked, setChecked] = useState(false)

  const check = useCallback(() => {
    if (!authenticated) {
      const source = searchParams.get('source') ?? undefined
      const sourceRef = searchParams.get('source_ref') ?? undefined
      const trialCode = searchParams.get('trial_code') ?? undefined
      const queryParams = new URLSearchParams({
        returnTo: `${window.location.pathname}${window.location.search}`,
      })
      if (source) {
        queryParams.set('source', source)
      }
      if (sourceRef) {
        queryParams.set('source_ref', sourceRef)
      }
      if (trialCode) {
        queryParams.set('trial_code', trialCode)
      }
      const queryString = queryParams.toString()

      const loginPath = loginPaths[method]

      const href = `${loginPath}${queryString}`

      router.replace(href)
    } else {
      setChecked(true)
    }
  }, [authenticated, method, router, searchParams])

  useEffect(() => {
    check()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!checked) {
    return null
  }

  return <FatalAuthErrorListener>{children}</FatalAuthErrorListener>
}

function FatalAuthErrorListener({ children }: { children: React.ReactNode }) {
  const { fatalError, logout } = useAuthContext()
  // const showDialog = useBoolean(false)
  const navigate = useNavigate()
  const [retryCount, setRetryCount] = useState(0)
  const [timeOfLastRefresh, setTimeOfLastRefresh] = useState<number | null>(null)
  const [isRetrying, setIsRetrying] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleLogout = useCallback(async () => {
    await logout().finally(() => {
      navigate(paths.auth.jwt.login(window.location.pathname))
    })
  }, [logout, navigate])

  const handleRefreshToken = useCallback(async () => {
    // if there is no fatal error, do not attempt to refresh
    if (!fatalError) return
    // if the retry count is at the max, do not attempt to refresh again
    if (isRetrying || retryCount >= MAX_RETRY_ATTEMPTS) return
    // if the last refresh was less than the retry delay, do not attempt to refresh again
    if (timeOfLastRefresh && timeOfLastRefresh + retryDelayMs(retryCount) > Date.now()) return

    // don't attempt to refresh if we're already retrying
    setIsRetrying(true)
    let refreshedSuccessfully = false
    try {
      const result = await refreshLoginSession()
      refreshedSuccessfully = !!result
    } finally {
      setRetryCount((r) => r + 1)
      setTimeOfLastRefresh(Date.now())
    }

    console.error('Token refresh result:', refreshedSuccessfully)
    if (refreshedSuccessfully) {
      setRetryCount(0)
      enqueueSnackbar('Reconnected to Ambient', { variant: 'success', autoHideDuration: 2000 })
    }
    setIsRetrying(false)
  }, [isRetrying, retryCount, timeOfLastRefresh, fatalError, enqueueSnackbar])

  const handleRetry = useCallback(() => {
    setRetryCount(0)
    setTimeOfLastRefresh(Date.now())
  }, [])

  useInterval(
    () => {
      handleRefreshToken()
    },
    fatalError ? 1000 : null
  )

  return (
    <>
      {children}
      <Dialog open={fatalError} sx={{ p: 4 }}>
        <DialogTitle>{retryCount >= MAX_RETRY_ATTEMPTS ? 'Connection Failed' : 'Reconnecting...'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {retryCount >= MAX_RETRY_ATTEMPTS
              ? "We've been unable to reconnect. Please try again or log out."
              : "We're experiencing connection issues. Attempting to reconnect..."}
          </DialogContentText>
          {retryCount < MAX_RETRY_ATTEMPTS && <LinearProgress sx={{ display: 'block', margin: '20px auto' }} />}
        </DialogContent>
        <DialogActions>
          {retryCount >= MAX_RETRY_ATTEMPTS && <Button onClick={handleRetry}>Retry</Button>}
          <Button onClick={handleLogout}>Logout</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

function retryDelayMs(retryCount: number) {
  return Math.min(1000 * 2 ** retryCount, MAX_RETRY_DELAY_MS)
}
