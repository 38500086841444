import { useLazyQuery, OnDataOptions, gql, useSubscription, useApolloClient, useReactiveVar } from '@apollo/client'
import { useCallback, useEffect } from 'react'
import {
  SubscriptionSubscription,
  CrudOperation,
  Post,
  ActionItemsUpdatedSubscription,
} from 'src/__generated__/graphql'
import {
  actionItemsUpdated,
  getActionItems,
  getFeedQuery,
  getMeetingSummaryDrafts,
  getPost,
  meetingSummaryUpdatedSubscription,
  postUpdatedSubscription,
} from './queries'
import { ConnectionStatus, subscriptionConnectionStatus } from './localState'

type Props = {
  children: React.ReactNode
}

export default function SubscriptionListener({ children }: Props) {
  const apolloClient = useApolloClient()
  const connectionStatus = useReactiveVar(subscriptionConnectionStatus)

  // when we reconnect, refetch all queries
  useEffect(() => {
    if (connectionStatus === ConnectionStatus.RECONNECTED) {
      apolloClient.reFetchObservableQueries()
    }
  }, [apolloClient, connectionStatus])

  const [refreshPostFromServer] = useLazyQuery(getPost, {
    fetchPolicy: 'network-only',
  })

  // the handler for the subscription
  const onSubscriptionData = useCallback(
    async ({ data, client }: OnDataOptions<SubscriptionSubscription>) => {
      const payload = data.data?.postUpdated
      if (payload) {
        // if the post was deleted, remove it from the feed
        // if a post was updated, refetch that post
        // otherwise, if it was a new post, pull the whole feed
        if (payload.operation === CrudOperation.Delete) {
          client.cache.modify({
            id: client.cache.identify({ __typename: 'Post', id: payload.postId }),
            fields: (_, { DELETE }) => DELETE,
          })
        } else if (payload.operation === CrudOperation.Update || payload.operation === CrudOperation.UpdateVisibility) {
          // only request from the server if we have the post in the cache or the visibility changed
          const cachedPost = client.cache.readFragment<Post>({
            id: client.cache.identify({ __typename: 'Post', id: payload.postId }),
            fragment: gql(/* GraphQL */ `
              fragment CacheTest on Post {
                id
              }
            `),
          })
          if (cachedPost || payload.operation === CrudOperation.UpdateVisibility) {
            const p = await refreshPostFromServer({
              variables: {
                id: payload.postId,
              },
              onError(error) {
                const errorCode = error.graphQLErrors[0]?.extensions?.code
                if (errorCode === 'FORBIDDEN' || errorCode === 'NOT_FOUND') {
                  // The post was probably made private, so remove it from the feed
                  client.cache.modify({
                    id: client.cache.identify({ __typename: 'Post', id: payload.postId }),
                    fields: (_, { DELETE }) => DELETE,
                  })
                }
              },
            })
            // now replace the feed listing if it exists
            if (p.data) {
              client.cache.writeFragment({
                id: client.cache.identify({ __typename: 'FeedPost', id: payload.postId }),
                fragment: gql(/* GraphQL */ `
                  fragment UpdatedPost on FeedPost {
                    title
                    meetingStartOrPostCreateTime
                    tags {
                      id
                      name
                    }
                    updatedAt
                  }
                `),
                data: {
                  ...p.data.post,
                },
              })
            }
          }
          // if the visibility changed, refetch the feed
          if (payload.operation === CrudOperation.UpdateVisibility) {
            client.refetchQueries({
              include: [getFeedQuery],
            })
          }
        } else {
          // if it was a new post, pull the whole feed again
          client.refetchQueries({
            include: [getFeedQuery],
          })
        }
      }
    },
    [refreshPostFromServer]
  )

  // Monitor for changes to posts and update the cache accordingly
  useSubscription(postUpdatedSubscription, {
    onData: onSubscriptionData,
  })

  useSubscription(meetingSummaryUpdatedSubscription, {
    onData: ({ client }) => {
      // if the meeting summary changed, refetch the feed
      client.refetchQueries({
        include: [getMeetingSummaryDrafts],
      })
    },
  })

  const onActionItemsUpdated = useCallback(({ data, client }: OnDataOptions<ActionItemsUpdatedSubscription>) => {
    const payload = data.data?.actionItemsUpdated
    if (payload) {
      payload.forEach((update) => {
        // if the action item was deleted, evict if from the cache
        // otherwise, if it was a new action item, pull the updated action items
        if (update.operation === CrudOperation.Delete) {
          client.cache.modify({
            id: client.cache.identify({ __typename: 'ActionItem', id: update.actionItemId }),
            fields: (_, { DELETE }) => DELETE,
          })
        } else {
          client.refetchQueries({
            include: [getActionItems],
          })
        }
      })
    }
  }, [])

  useSubscription(actionItemsUpdated, {
    onData: onActionItemsUpdated,
  })
  return children
}
