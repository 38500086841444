import { forwardRef } from 'react'
// @mui
import Link from '@mui/material/Link'
import Box, { BoxProps } from '@mui/material/Box'
// routes
import { RouterLink } from 'src/routes/components'
import { useSystemThemeMode } from 'src/hooks/use-system-theme-mode'
import { useSettingsContext } from '../settings/context'
import { SettingsValueProps } from '../settings/types'

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean
  // the ability to override the logo
  logoColorMode?: SettingsValueProps['logoColorMode']
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(({ disabledLink = false, sx, logoColorMode, ...other }, ref) => {
  const { logoColorMode: themeLogoColorMode, themeMode } = useSettingsContext()
  const { currentThemeMode } = useSystemThemeMode()

  const monochromeLogo = (
    <Box ref={ref} component="div" sx={{ width: 30, height: 28, cursor: 'pointer', ...sx }} {...other}>
      <svg viewBox="0 0 310 300" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M165.903 7.27466C159.021 -2.42489 144.622 -2.42489 137.739 7.27466L3.18801 196.893C-2.74035 205.248 -0.133182 216.897 8.79088 221.927L143.342 297.775C148.606 300.742 155.037 300.742 160.301 297.775L294.852 221.927C303.776 216.897 306.383 205.248 300.455 196.893L165.903 7.27466ZM136.46 45.1749L26.9835 199.732C24.7654 202.864 25.7419 207.226 29.0834 209.113L137.86 270.541C140.738 272.166 144.3 270.087 144.3 266.782V47.67C144.3 43.4617 138.893 41.7408 136.46 45.1749Z"
          fill={currentThemeMode(themeMode) === 'light' ? '#000' : '#fff'}
        />
      </svg>
    </Box>
  )

  // OR using local (public folder)
  // -------------------------------------------------------
  const colorLogo = (
    <Box component="div" ref={ref} sx={{ width: 30, height: 29, cursor: 'pointer', ...sx }} {...other}>
      <svg viewBox="0 0 310 300" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M165.903 7.27466C159.021 -2.42489 144.622 -2.42489 137.739 7.27466L3.18801 196.893C-2.74035 205.248 -0.133182 216.897 8.79088 221.927L143.342 297.775C148.606 300.742 155.037 300.742 160.301 297.775L294.852 221.927C303.776 216.897 306.383 205.248 300.455 196.893L165.903 7.27466ZM136.46 45.1749L26.9835 199.732C24.7654 202.864 25.7419 207.226 29.0834 209.113L137.86 270.541C140.738 272.166 144.3 270.087 144.3 266.782V47.67C144.3 43.4617 138.893 41.7408 136.46 45.1749Z"
          fill="url(#paint0_linear_113_1057)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_113_1057"
            x1="303.404"
            y1="301.088"
            x2="119.445"
            y2="-64.3725"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5544BB" />
            <stop offset="0.401967" stopColor="#2299AA" />
            <stop offset="1" stopColor="#BFD46B" />
            <stop offset="1" stopColor="#BBDD44" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  )

  const cm = logoColorMode ?? themeLogoColorMode
  const logo = cm === 'monochrome' ? monochromeLogo : colorLogo

  if (disabledLink) {
    return logo
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  )
})

export default Logo
