import { Theme } from '@mui/material/styles'
import { DialogProps } from '@mui/material/Dialog'

// ----------------------------------------------------------------------

export function dialog(theme: Theme) {
  return {
    MuiDialog: {
      defaultProps: {
        transitionDuration: 128,
      },
      styleOverrides: {
        paper: ({ ownerState }: { ownerState: DialogProps }) => ({
          boxShadow: theme.customShadows.dialog,
          borderRadius: theme.shape.borderRadius * 1.2,
          ...(!ownerState.fullScreen && {
            margin: theme.spacing(2),
          }),
          // give the dialog's border more definition
          border: `1px solid ${theme.palette.divider}`,
          // borderTop: 'none',
        }),
        paperFullScreen: {
          borderRadius: 0,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1.5, 3),
          backgroundColor: theme.palette.background.neutral,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: `${theme.spacing(2, 2, 1)} !important`,
        },
        dividers: {
          borderTop: 0,
          borderBottomStyle: 'dashed',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
          '& > :not(:first-of-type)': {
            marginLeft: theme.spacing(1.5),
          },
        },
      },
    },
  }
}
