import { ActionItemTableAuthEventListener } from 'src/components/action-items-table/persistant-state'

/**
 * Register global event listeners for authentication events that may need to update
 * persistant state even if their component is not mounted.
 * @param param0
 * @returns
 */
export function GlobalAuthEventListeners() {
  return <ActionItemTableAuthEventListener />
}
