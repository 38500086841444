const DESKTOP_HEADER_HEIGHT = 34
const DESKTOP_TRAFFIC_LIGHT_WIDTH = 50

/**
 * Whether it is a desktop application
 */
export function useDesktopApp() {
  const isDesktopApp = Object.hasOwn(window, 'todesktop')

  return {
    isDesktopApp,
    desktopHeaderHeight: isDesktopApp ? DESKTOP_HEADER_HEIGHT : 0,
    desktopTrafficLightWidth: isDesktopApp ? DESKTOP_TRAFFIC_LIGHT_WIDTH : 0,
  }
}
