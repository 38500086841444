import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
// layouts
import CompactLayout from 'src/layouts/compact'
// components
import { SplashScreen } from 'src/components/loading-screen'

// ----------------------------------------------------------------------

const Page500 = lazy(() => import('src/pages/500'))
const Page403 = lazy(() => import('src/pages/403'))
const Page404 = lazy(() => import('src/pages/404'))
const MaintenancePage = lazy(() => import('src/pages/maintenance'))

// ----------------------------------------------------------------------

export const errorRoutes = [
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: 'maintenance', element: <MaintenancePage /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
]
