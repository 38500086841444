import { lazy, Suspense } from 'react'
import { GuestGuard } from 'src/auth/guard'
// auth
// layouts
// components
import { LoadingScreen } from 'src/components/loading-screen'
import PublicShareLayout from 'src/layouts/share'
import { paths } from '../paths'

// ----------------------------------------------------------------------

// PRODUCT
const PublicPostPage = lazy(() => import('src/pages/public-post'))

// ----------------------------------------------------------------------

export const shareRoutes = [
  {
    path: 'share/:slug',
    element: (
      <GuestGuard forwardTo={(params) => paths.dashboard.post.details(params.slug!, false, true)}>
        <PublicShareLayout>
          <Suspense fallback={<LoadingScreen />}>
            <PublicPostPage />
          </Suspense>
        </PublicShareLayout>
      </GuestGuard>
    ),
  },
]
